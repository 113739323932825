import React, { useState, useEffect } from 'react';
import { Button } from "../../components/buttons/button.default";
import { InputAutoComplete } from "../../components/input/input.autocomplete";
import { LabelInput } from "../../components/label/label.input";
import { FiAlertTriangle, FiArrowLeft, FiChevronRight, FiSearch, FiTrash2, FiX } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import Loader from '../../components/Loader/loader';
import { useMenu } from '../../hooks/useMenu';
import { TableDefault } from '../../components/table/table.default';
import { useModal } from '../../hooks/useModal';
import Modal from '../../components/Modal/modal';
import { object } from 'underscore';

export function CompanyUser(){

    const token =  localStorage.getItem('@auth:token')
    const username = localStorage.getItem('@user:name')
    const navigate = useNavigate()
    const { setMenuTitle } = useMenu()
    const { setShow, show } = useModal()
    setMenuTitle('Empresa e Usuários')
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [usersCompany, setUsersCompany] = useState([])
    const [usersList, setUsersList] = useState([])
    const [user, setUser] = useState('')
    const [registers, setRegisters] = useState([])
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    const [ buttonRemove, setButtonRemove ] = useState(false)
    const [ userTemp, setUserTemp ] = useState()
    const [ errorUserEmpty, setErrorUserEmpty ] = useState(false)

    useEffect(() => {
        setShow(false)
        setLoading(true)

        async function getData(){

          const companiesApi = await api.get('/api/v1/companiesConnect', {
            headers: {
                authorization: token
            }
          })

            setCompanies(companiesApi.data.data?.map(item => {
                const setCompanyWithBaseAtOrigin = item?.companyOrigin == 'questor' ? item?.id_questor : item?.id_senior
                return {
                    ...item,
                    label: `${setCompanyWithBaseAtOrigin} - ${item?.name}`,
                    nomemp: item?.name,
                    value: setCompanyWithBaseAtOrigin
                }
            }))
            setLoading(false)

        }

        async function getUsers(){

            const getUsersApi = await api.get('/api/v1/users', {
                headers: {
                    authorization: token
                }
            })

            setUsersList(getUsersApi?.data.map((user) => {
                return {
                    ...user,
                    access: user.access ? typeAccess[user.access - 1]?.name : '',
                    inputDescription: `${user.fullname} - ${typeAccess[user.access - 1]?.name}`
                }
            })?.filter(item => item?.active))
            setLoading(false)

        }
            
        getData()
        getUsers()
        
      }, [registers]);

    useEffect(() => {
        setLoading(true)

        async function getUsersCompany(){
            if (company !== null && company !== undefined){
                
                const usersCompanyApi = await api.get(`/api/v1/users/company/${company}`, {
                    headers: {
                        authorization: token
                    }
                })
                
                setUsersCompany(usersCompanyApi?.data?.data.map((user) => {
                    return {
                        ...user,
                        access: user.access ? typeAccess[user.access - 1]?.name : '',
                        remove: <FiTrash2 className="cursor-pointer ml-[50%] text-red-800" onClick={() => {setShow(true); setButtonRemove(true); setUserTemp(user)}} title='Excluir'/>
                    }
                }))
                setLoading(false)
            }
        }

        getUsersCompany()

    }, [company, registers])

    useEffect(() => {
        if (!show){
            setUserTemp()
            setButtonRemove(false)
            setErrorUserEmpty(false)
        }
    }, [show])

    async function handleSubmit(){
        
        if(user === null || user === "null" || user === "" || user === undefined){
            setShow(true)
            return setErrorUserEmpty(true)
        }else{
            try{
                api.post('/api/v1/users/linkCompanies', {
                user_id: user.id,
                company: company
                },{
                headers: {
                    Authorization: token,
                }
                }).then((response, error) => {
                    if(error){
                        return alert("Erro ao realizar cadastro.");
                    }
                    
                    setRegisters([]);
                    setShowNotificationModal(true)
                    return setNotificationModalText('Usuário vinculado com sucesso')
                });

            } catch(error){
                return alert("Erro ao realizar cadastro!")
            }
        }
    }

    async function handleDelete(user){
        setLoading(true)
        if(user.company === null || user.company === "" || user.company === undefined){
            return alert("Erro ao realizar exclusão");
          }
      
          try{
            await api.delete(`/api/v1/users/linkCompanies/${user.user_id}/${user.company}`, {
              headers: {
                authorization: token
              }
            });
            
            setRegisters([]);
            setShowNotificationModal(true)
            return setNotificationModalText('Usuário desvinculado com sucesso')

          }catch(error){
            return alert("Erro ao realizar exclusão, fale com o administrador");
          }
    }

    const typeAccess = [
        {
            "value": 1,
            "name": "Administrador"
        },
        {
            "value": 2,
            "name": "Folpag - RH"
        },
        {
            "value": 3,
            "name": "Cliente"
        },
        {
            "value": 4,
            "name": "Cliente - Admissão"
        }
    ]

    const tableThead = [
        {
            "name":"Código",
            "original_name": "user_id"
        },
        {
            "name":"Usuário",
            "original_name": "fullname"
        },
        {
            "name":"Tipo Acesso",
            "original_name": "access"
        },
        {
            "name":"Excluir",
            "original_name": "remove"
        }
    ]
  
    return(
        <div className={loading ? 'flex flex-row h-[70%] items-center justify-center' : ''}>
            {
                show && buttonRemove ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <p className='mr-7'>Deseja realmente desvincular esse usuário?</p> 
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                            </div>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => {setShow(false); setButtonRemove(false)}}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleDelete(userTemp)}}>Confirmar</Button>
                            </div>
                        </div>
                    </Modal>
                : show && errorUserEmpty ?
                    <Modal>
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                    <FiAlertTriangle className='text-red-700 mr-7'/> Selecione um usuário
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                            </div>
                        </div>
                    </Modal>
                : show ?
                    <Modal>
                        <div className={`${!show ? 's' : 'conf'} p-2 flex flex-col justify-center items-center bg-gray-200 rounded-lg w-[30rem]`}>          
                            <p className='flex flex-row items-center font-extrabold text-primaryDefaultLight text-lg'>
                                <FiChevronRight /> Vincule um usuário para essa empresa.
                            </p>
                                <div className='flex justify-center items-center mt-2 font-black'>{companyName}</div>
                            <form className='flex flex-col gap-5 h-full mt-7'>
                                <LabelInput>
                                    <InputAutoComplete
                                        data={usersList}
                                        selectedLabel={'inputDescription'}
                                        optionList={['inputDescription']}                                
                                        onChange={(e) => {
                                            setUser(e)
                                        }}
                                        placeHolder='Selecione um Usuário'
                                        
                                    />
                                </LabelInput>
                                <div className='flex flex-col justify-center align-center mt-10 ml-32'>
                                    <Button onClick={e => handleSubmit()}>Vincular</Button>
                                </div>
                            </form>
                        </div>
                    </Modal>
                :
                <></>
            }
            <div className='hidden sm:flex flex-row justify-center items-center mt-12'>
                <form className={'flex flex-row justify-center items-center mr-8 ml-9'}>
                    {
                        loading ? 
                        <></>
                        :
                        <LabelInput>
                            <InputAutoComplete
                                data={companies}
                                selectedLabel={'label'}
                                optionList={['label']}
                                value={companyName ? companyName : ''}
                                placeHolder={'Selecione uma empresa'}
                                onChange={(e) => {
                                    if (e){
                                        setCompanyName(e.label);
                                        if (typeof(e) == 'object') {
                                            setCompany(e.id);
                                        }
                                    }
                                }}
                            />
                        </LabelInput>            
                    }           
                </form>
            </div>
            {
                loading ? 
                <div className='flex flex-col'>
                    <Loader />
                </div>
                : usersCompany.length === 0 ?
                <>
                    <div className='hidden sm:flex animate-[wiggleLeft_1s_ease-in-out] justify-center items-center text-2xl mt-32 text-primaryDefaultLight font-black'>
                        Selecione uma Empresa
                    </div>
                    <div className='flex lg:hidden w-full items-center justify-center py-8 mt-8'>
                        <p className='text-titleBlackTextLight text-base text-center'>
                            Esta funcionalidade está disponível apenas para computador
                        </p>
                    </div>
                </>
                :
                    <div className="animate-[wiggleLeft_1s_ease-in-out] hidden sm:flex flex-col justify-start align-center ml-20 mt-10 mr-8 gap-5 h-full max-h-[80vh]">
                        <TableDefault
                            title={tableThead}
                            data={usersCompany}
                            quantity={usersCompany.length}
                            buttonFunction={setShow}
                            buttonFunctionParam={true}
                            buttonDescription={'+ Novo Usuário'}
                            collumns={["user_id", "fullname", "access","remove"]}
                        />
                    </div>
            }
        </div>
    )
}