import React, { useState, useEffect } from 'react';
import { FiPlus, FiUserPlus, FiX, FiChevronRight, FiAlertTriangle } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
//services
import api from '../../services/api';
//hooks
import { useCompanyBranch } from '../../hooks/useCompanyBranch';
import { useMenu } from '../../hooks/useMenu';
import { useModal } from '../../hooks/useModal';
import { useAuth } from '../../hooks/useAuth';
//components
import Loader from '../../components/Loader/loader';
import Modal from '../../components/Modal/modal';
import { Input } from '../../components/input/input';
import { Button } from '../../components/buttons/button.default';
//css
import './requestAdmission.css';
//utils
import { cpfMask } from '../../utils/formatText/cpfMask';
import { pisMask } from '../../utils/formatText/pisMask';
import { validarPIS } from '../../utils/validatorPIS';
import { justNumber } from '../../utils/justNumber';
import { reportStyle } from './reportStyle';
import { maskCep } from '../../utils/formatText/cepMask';
import { cnpjMask } from '../../utils/formatText/cnpjMask';

export default function RequestAdmission() {

    //Modal de Notificação
    const { showNotificationModal, setShowNotificationModal, notificationModalText, setNotificationModalText } = useModal()
    //State de erro Submit
    const [errorSubmit, setErrorSubmit] = useState(false)
    // State para modal no botão de salvar
    const [buttonSave, setButtonSave] = useState(false)

    const thisYear = new Date().getUTCFullYear()
    const [selectedForm, setSelectedForm] = useState(1)
    const [missingRequiredFields, setMissingRequiredFields] = useState({ 'employeeName': false })
    const [missingRequiredFieldsModal, setMissingRequiredFieldsModal] = useState({})
    const [requiredFieldsForm, setRequiredFieldForm] = useState('')
    const [errorFields, setErrorFields] = useState({ 'birthday': '' })
    const [loading, setLoading] = useState(true)
    const [insideLoading, setInsideLoading] = useState(false)
    const { setMenuTitle, menuStatus } = useMenu()
    const { company, branch, branchCnpj, companyName } = useCompanyBranch();
    const { setShow, show } = useModal()
    const { save_id } = useParams()
    const { user } = useAuth()    
    setMenuTitle('Admissão')
    const navigate = useNavigate()
    const [dateError, setDateError] = useState({})

    // Api Datas
    const [nationalities, setNationalities] = useState([])
    const [nationalitiesState, setNationalitiesState] = useState([])
    const [nationalitiesStateCities, setNationalitiesStateCities] = useState([])
    const [banks, setBanks] = useState([])
    const [agencies, setAgencies] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [districts, setDistricts] = useState([])
    const [brazilStates, setBrazilStates] = useState([])
    const [companyConfig, setCompanyConfig] = useState('')

    // Dados do Contrato
    const [employeeName, setEmployeeName] = useState('')
    const [admissionDate, setAdmissionDate] = useState('')
    const [invalidAdmissionDate, setInvalidAdmissionDate] = useState(false)
    const [typeBusinessLink, setTypeBusinessLink] = useState('')
    const [typeApuration, setTypeApuration] = useState(1)
    const [employeeLaborUnion, setEmployeeLaborUnion] = useState(false)
    const [laborUnion, setLaborUnion] = useState('')
    const [employeePosition, setEmployeePosition] = useState('')
    const [employeeScheduleType, setEmployeeScheduleType] = useState('')
    const [employeeScheduleMonday, setEmployeeScheduleMonday] = useState('')
    const [employeeScheduleTuesday, setEmployeeScheduleTuesday] = useState('')
    const [employeeScheduleWednesday, setEmployeeScheduleWednesday] = useState('')
    const [employeeScheduleThursday, setEmployeeScheduleThursday] = useState('')
    const [employeeScheduleFriday, setEmployeeScheduleFriday] = useState('')
    const [employeeScheduleSaturday, setEmployeeScheduleSaturday] = useState('')
    const [employeeScheduleSunday, setEmployeeScheduleSunday] = useState('')
    const [employeeSalary, setEmployeeSalary] = useState('')
    const [typeSalary, setTypeSalary] = useState(-1)
    const [employeeLocal, setEmployeeLocal] = useState('')
    const [employeeCCU, setEmployeeCCU] = useState('')
    const [employeeWorkplace, setEmployeeWorkplace] = useState('')
    const [employeeVT, setEmployeeVT] = useState(false)
    const [employeeValueVT, setEmployeeValueVT] = useState(0)
    const [advanceSalary, setAdvanceSalary] = useState(false)
    const [advanceSalaryPorcentage, setAdvanceSalaryPorcentage] = useState(0)
    const [employeeExperience, setEmployeeExperience] = useState(-1)
    const [lifeInsurance, setLifeInsurance] = useState(false)
    const [insalubrity, setInsalubrity] = useState(false)
    const [periculosity, setPericulosity] = useState(false)
    const [paymentPeriod, setPaymentPeriod] = useState('M')
    const [paymentMethod, setPaymentMethod] = useState('D')
    const [employeeBank, setEmployeBank] = useState(-1)
    const [employeBankAgencie, setEmployeBankAgencie] = useState('')
    const [employeeBankAccountType, setEmployeeBankAccountType] = useState(-1)
    const [employeeBankAccount, setEmployeeBankAccount] = useState('')
    const [employeeBankAccountDigit, setEmployeeBankAccountDigit] = useState('')
    const [isIntermittent , setIsIntermittent] = useState()
    const [observation, setObservation] = useState('')
    const [traineeInsurancePolicy, setTraineeInsurancePolicy] = useState('')
    const [traineeEndForecast, setTraineeEndForecast] = useState('')
    const [traineeEducationalInstitution, setTraineeEducationalInstitution] = useState('')
    const [traineeCodSupervisor, setTraineeCodSupervisor] = useState('')
    const [traineeOrigin, setTraineeOrigin] = useState('')
    const [traineeEducationLevel , setTraineeEducationLevel] = useState('')
    const [advanceMonthAdmission, setAdvanceMonthAdmission] = useState('')
    const [natureExpense, setNatureExpense] = useState('')
    const [insalubrityPercentage, setInsalubrityPercentage] = useState('')
    const [periculosityPercentage, setPericulosityPercentage] = useState('')
    const [digitAgency, setDigitAgency] = useState('')
    const [apprenticeInstitutionName, setApprenticeInstitutionName] = useState('')
    const [apprenticeInstitutionCNPJ, setApprenticeInstitutionCNPJ] = useState('')
    const [apprenticeDateFinish, setApprenticeDateFinish] = useState('')
    const [gratificationFunction, setGratificationFunction] = useState(false)

    //Dados do Empregado
    const [nickname, setNickname] = useState('')
    const [employeeSexualGender, setEmployeeSexualGender] = useState('')
    const [civilStatus, setCivilStatus] = useState(-1)
    const [levelOfEducation, setLevelOfEducation] = useState(-1)
    const [birthday, setBirthday] = useState('')
    const [invalidBirthday, setInvalidBirthday] = useState(false)
    const [nationality, setNationality] = useState('')
    const [employeeUf, setEmployeeUf] = useState('')
    const [employeeCity, setEmployeeCity] = useState(0)
    const [wordCardType, setSelectedWorkCardType] = useState('0')
    const [firstJob, setFirstJob] = useState(false)
    const [workCard, setWorkCard] = useState('')
    const [workCardSerie, setWorkCardSerie] = useState('')
    const [workCardEmission, setWorkCardEmission] = useState('')
    const [workCardUf, setWorkCardUf] = useState(-1)
    const [cpf, setCpf] = useState('')
    const [pis, setPis] = useState('')
    const [emissionPis, setEmissionPis] = useState('')
    const [rg, setRg] = useState('')
    const [sendingAgencyRG, setSendingAgencyRg] = useState('')
    const [EmissionRG, setEmissionRg] = useState('')
    const [rgUf, setRgUf] = useState('')
    const [voterRegistration, setVoterRegistration] = useState('')
    const [voterRegistrationZone, setVoterRegistrationZone] = useState('')
    const [voterRegistrationSection, setVoterRegistrationSection] = useState('')
    const [cnh, setCnh] = useState('')
    const [cnhExists, setCnhExists] = useState(false)
    const [cnhIssuer, setCnhIssuer] = useState('')
    const [cnhCategory, setCnhCategory] = useState('')
    const [cnhValidity, setCnhValidity] = useState('')
    const [cnhUf, setCnhUf] = useState('')
    const [cnhFirstDate, setCnhFirstDate] = useState('')
    const [cnhDispatchDate, setCnhDispatchDate] = useState('')
    const [militaryServiceCard, setMilitaryServiceCard] = useState('')
    const [militaryServiceCardUf, setMilitaryServiceCardUf] = useState('')
    const [deficiency, setDeficiency] = useState(false)
    const [deficiencyType, setDeficiencyType] = useState(-1)
    const [deficiencyRehabilitatedInss, setDeficiencyRehabilitatedInss] = useState(false)
    const [deficiencyPcdQuote, setDeficiencyPcdQuote] = useState(false)
    const [employeeRace, setEmployeeRace] = useState('')
    const [addressCountry, setAddressCountry] = useState(-1)
    const [addressCep, setAddressCep] = useState('')
    const [addressState, setAddressState] = useState('')
    const [addressType, setAddressType] = useState('R')
    const [addressCity, setAddressCity] = useState(-1)
    const [addressDistrict, setAddressDistrict] = useState('')
    const [addressRoad, setAddressRoad] = useState('')
    const [addressNumber, setAddressNumber] = useState('')
    const [addressComplement, setAddressComplement] = useState('')
    const [phoneDdd, setPhoneDdd] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [employeeEmail, setEmployeeEmail] = useState('')
    const [employeeEmailCorporate, setEmployeeEmailCorporate] = useState('')
    const [dateEmploymentExamination, setDateEmploymentExamination] = useState('')
    const [invalidCPF, setInvalidCPF] = useState(false)
    const [invalidDependentCPF, setInvalidDependentCPF] = useState([])

    const [foreignResidenceTime, setForeignResidenceTime] = useState('')
    const [foreignCondition, setForeignCondition] = useState('')
    const [foreignFinishResidence, setForeignFinishResidence] = useState('')
    const [foreignArrivalDate, setForeignArrivalDate] = useState('')
    const [foreignRegistry, setForeignRegistry] = useState('')
    const [foreignDateExpRegistry, setForeignDateExpRegistry] = useState('')
    const [foreignVisaValidity, setForeignVisaValidity] = useState('')
    const [foreignDateValidityCTPS, setForeignDateValidityCTPS] = useState('')
    const [foreignCasWithBrazilian, setForeignCasWithBrazilian] = useState('')
    const [foreignBrazilianChildren, setForeignBrazilianChildren] = useState('')

    //Dados dos dependentes
    const [fatherName, setFatherName] = useState('')
    const [motherName, setMotherName] = useState('')
    const [dependents, setDependents] = useState([])

    useEffect(() => {
        if (!show){
            setErrorSubmit(false)
            setButtonSave(false)
        }
    }, [show])

    useEffect(() => {
        if (company === 0) {
            alert('Para acessar essa pagina você precisa selecionar uma empresa e uma filial antes.')
            return navigate('/')
        }
    
        async function getDatas() {
            try {
                const getCompanyConfigAPI = await api.get(`api/v1/companies/getCompanyConfig/company/${company}?branch=${branch}`, {
                    headers: {
                        authorization: user?.token
                    }
                })
                setCompanyConfig(getCompanyConfigAPI?.data?.data[0])

                const getNationalities = await api.get('api/v1/geo/nationalities', {
                    headers: {
                        authorization: user?.token
                    }
                })
                setNationalities(getNationalities.data.data)

                const getBanks = await api.get('api/v1/employees/banks', {
                    headers: {
                        authorization: user?.token
                    }
                })
                setBanks(getBanks.data.data)

                const getCountries = await api.get('api/v1/geo/countries', {
                    headers: {
                        authorization: user?.token
                    }
                })
                setCountries(getCountries.data.data)

                const getBrazilStates = await api.get(`api/v1/geo/states/country/1`, {
                    headers: {
                        authorization: user?.token
                    }
                })
                setInsideLoading(false)
                setBrazilStates(getBrazilStates.data.data)
            } catch (error) {
                setLoading(false)
                return alert('Erro ao tentar realizar buscar de dados. Se o problema persistir entre em contato com o suporte.')
            }

            setLoading(false)
        }
        getDatas()

        if (save_id){

            async function employeeSaveData(){

                try {

                    const getEmployeeSave = await api.get(`api/v1/employeesSave/company/${company}/branch/${branch}/save_id/${save_id}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
    
                    setEmployeeName(getEmployeeSave?.data?.data?.recordset[0].employeeName ? getEmployeeSave?.data?.data?.recordset[0].employeeName : '')
                    setAdmissionDate(getEmployeeSave?.data?.data?.recordset[0].admissionDate ? getEmployeeSave?.data?.data?.recordset[0].admissionDate.split('T')[0] : '')
                    setTypeBusinessLink(getEmployeeSave?.data?.data?.recordset[0].typeBusinessLink ? getEmployeeSave?.data?.data?.recordset[0].typeBusinessLink : 10)
                    setTypeApuration(getEmployeeSave?.data?.data?.recordset[0].typeApuration ? getEmployeeSave?.data?.data?.recordset[0].typeApuration : 1)
                    setEmployeeLaborUnion(getEmployeeSave?.data?.data?.recordset[0].employeeLaborUnion ? getEmployeeSave?.data?.data?.recordset[0].employeeLaborUnion : false)
                    setLaborUnion(getEmployeeSave?.data?.data?.recordset[0].laborUnion ? getEmployeeSave?.data?.data?.recordset[0].laborUnion : '')
                    setEmployeePosition(getEmployeeSave?.data?.data?.recordset[0].employeePosition ? getEmployeeSave?.data?.data?.recordset[0].employeePosition : '')
                    setEmployeeScheduleType(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleType ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleType : '')
                    setEmployeeScheduleMonday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleMonday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleMonday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleMonday : '')
                    setEmployeeScheduleTuesday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleTuesday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleTuesday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleTuesday : '')
                    setEmployeeScheduleWednesday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleWednesday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleWednesday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleWednesday : '')
                    setEmployeeScheduleThursday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleThursday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleThursday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleThursday : '')
                    setEmployeeScheduleFriday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleFriday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleFriday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleFriday : '')
                    setEmployeeScheduleSaturday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSaturday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSaturday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSaturday : '')
                    setEmployeeScheduleSunday(getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSunday && getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSunday != 'n/d' ? getEmployeeSave?.data?.data?.recordset[0].employeeScheduleSunday : '')
                    setEmployeeSalary(getEmployeeSave?.data?.data?.recordset[0].employeeSalary ? getEmployeeSave?.data?.data?.recordset[0].employeeSalary : '')
                    setTypeSalary(getEmployeeSave?.data?.data?.recordset[0].typeSalary ? getEmployeeSave?.data?.data?.recordset[0].typeSalary : -1)
                    setEmployeeLocal(getEmployeeSave?.data?.data?.recordset[0].employeeLocal ? getEmployeeSave?.data?.data?.recordset[0].employeeLocal : '')
                    setEmployeeCCU(getEmployeeSave?.data?.data?.recordset[0].employeeCCU ? getEmployeeSave?.data?.data?.recordset[0].employeeCCU : '')
                    setEmployeeWorkplace(getEmployeeSave?.data?.data?.recordset[0].employeeWorkplace ? getEmployeeSave?.data?.data?.recordset[0].employeeWorkplace : '')
                    setEmployeeVT(getEmployeeSave?.data?.data?.recordset[0].employeeVT ? getEmployeeSave?.data?.data?.recordset[0].employeeVT : false)
                    setEmployeeValueVT(getEmployeeSave?.data?.data?.recordset[0].employeeValueVT ? getEmployeeSave?.data?.data?.recordset[0].employeeValueVT : 0)
                    setAdvanceSalary(getEmployeeSave?.data?.data?.recordset[0].advanceSalary ? getEmployeeSave?.data?.data?.recordset[0].advanceSalary : false)
                    setAdvanceSalaryPorcentage(getEmployeeSave?.data?.data?.recordset[0].advanceSalaryPorcentage ? getEmployeeSave?.data?.data?.recordset[0].advanceSalaryPorcentage : 0)
                    setEmployeeExperience(getEmployeeSave?.data?.data?.recordset[0].employeeExperience ? getEmployeeSave?.data?.data?.recordset[0].employeeExperience : -1)
                    setLifeInsurance(getEmployeeSave?.data?.data?.recordset[0].lifeInsurance ? getEmployeeSave?.data?.data?.recordset[0].lifeInsurance : false)
                    setInsalubrity(getEmployeeSave?.data?.data?.recordset[0].insalubrity ? getEmployeeSave?.data?.data?.recordset[0].insalubrity : false)
                    setPericulosity(getEmployeeSave?.data?.data?.recordset[0].periculosity ? getEmployeeSave?.data?.data?.recordset[0].periculosity : false)
                    setPaymentPeriod(getEmployeeSave?.data?.data?.recordset[0].paymentPeriod ? getEmployeeSave?.data?.data?.recordset[0].paymentPeriod : 'M')
                    setPaymentMethod(getEmployeeSave?.data?.data?.recordset[0].paymentMethod ? getEmployeeSave?.data?.data?.recordset[0].paymentMethod : 'R')
                    setEmployeBank(getEmployeeSave?.data?.data?.recordset[0].employeeBank ? getEmployeeSave?.data?.data?.recordset[0].employeeBank : -1)
                    setEmployeBankAgencie(getEmployeeSave?.data?.data?.recordset[0].employeBankAgencie ? getEmployeeSave?.data?.data?.recordset[0].employeBankAgencie : -1)
                    setEmployeeBankAccountType(getEmployeeSave?.data?.data?.recordset[0].employeeBankAccountType ? getEmployeeSave?.data?.data?.recordset[0].employeeBankAccountType : -1)
                    setEmployeeBankAccount(getEmployeeSave?.data?.data?.recordset[0].employeeBankAccount ? getEmployeeSave?.data?.data?.recordset[0].employeeBankAccount : '')
                    setEmployeeBankAccountDigit(getEmployeeSave?.data?.data?.recordset[0].employeeBankAccountDigit ? getEmployeeSave?.data?.data?.recordset[0].employeeBankAccountDigit : '')
                    setObservation(getEmployeeSave?.data?.data?.recordset[0].observation ? getEmployeeSave?.data?.data?.recordset[0].observation : '')
                    setTraineeInsurancePolicy(getEmployeeSave?.data?.data?.recordset[0]?.traineeInsurancePolicy ? getEmployeeSave?.data?.data?.recordset[0]?.traineeInsurancePolicy : '' )
                    setTraineeEndForecast(getEmployeeSave?.data?.data?.recordset[0]?.traineeEndForecast ? getEmployeeSave?.data?.data?.recordset[0]?.traineeEndForecast.split('T')[0] : '' )
                    setTraineeEducationalInstitution(getEmployeeSave?.data?.data?.recordset[0]?.traineeEducationalInstitution ? getEmployeeSave?.data?.data?.recordset[0]?.traineeEducationalInstitution : '' )
                    setTraineeCodSupervisor(getEmployeeSave?.data?.data?.recordset[0]?.traineeCodSupervisor ? getEmployeeSave?.data?.data?.recordset[0]?.traineeCodSupervisor : '' )
                    setTraineeOrigin(getEmployeeSave?.data?.data?.recordset[0]?.traineeOrigin ? getEmployeeSave?.data?.data?.recordset[0]?.traineeOrigin : '' )
                    setTraineeEducationLevel(getEmployeeSave?.data?.data?.recordset[0]?.traineeEducationLevel ? getEmployeeSave?.data?.data?.recordset[0]?.traineeEducationLevel : '' )
                    setAdvanceMonthAdmission(getEmployeeSave?.data?.data?.recordset[0]?.advanceMonthAdmission ? getEmployeeSave?.data?.data?.recordset[0]?.advanceMonthAdmission : '' )
                    setNatureExpense(getEmployeeSave?.data?.data?.recordset[0]?.natureExpenses ? getEmployeeSave?.data?.data?.recordset[0]?.natureExpenses : '' )
                    setInsalubrityPercentage(getEmployeeSave?.data?.data?.recordset[0]?.insalubrityPercentage ? getEmployeeSave?.data?.data?.recordset[0]?.insalubrityPercentage : '')
                    setPericulosityPercentage(getEmployeeSave?.data?.data?.recordset[0]?.periculosityPercentage ? getEmployeeSave?.data?.data?.recordset[0]?.periculosityPercentage : '')
                    setDigitAgency(getEmployeeSave?.data?.data?.recordset[0]?.digitAgency ? getEmployeeSave?.data?.data?.recordset[0]?.digitAgency : '')
                    setApprenticeInstitutionName(getEmployeeSave?.data?.data?.recordset[0]?.apprenticeInstitutionName ?? '')
                    setApprenticeInstitutionCNPJ(getEmployeeSave?.data?.data?.recordset[0]?.apprenticeInstitutionCNPJ ?? '')                    
                    setApprenticeDateFinish(getEmployeeSave?.data?.data?.recordset[0]?.apprenticeDateFinish ? getEmployeeSave?.data?.data?.recordset[0]?.apprenticeDateFinish.split('T')[0] : '')
                    setGratificationFunction(getEmployeeSave?.data?.data?.recordset[0]?.gratificationFunction ?? false)
    
                    setNickname(getEmployeeSave?.data?.data?.recordset[0].nickname ? getEmployeeSave?.data?.data?.recordset[0].nickname : '')
                    setEmployeeSexualGender(getEmployeeSave?.data?.data?.recordset[0].employeeSexualGender ? getEmployeeSave?.data?.data?.recordset[0].employeeSexualGender : '')
                    setCivilStatus(getEmployeeSave?.data?.data?.recordset[0].civilStatus ? getEmployeeSave?.data?.data?.recordset[0].civilStatus : -1)
                    setLevelOfEducation(getEmployeeSave?.data?.data?.recordset[0].levelOfEducation ? getEmployeeSave?.data?.data?.recordset[0].levelOfEducation : -1)
                    setBirthday(getEmployeeSave?.data?.data?.recordset[0].birthday ? getEmployeeSave?.data?.data?.recordset[0].birthday.split('T')[0] : '')
                    setNationality(getEmployeeSave?.data?.data?.recordset[0].nationality ? getEmployeeSave?.data?.data?.recordset[0].nationality : '')
                    setEmployeeUf(getEmployeeSave?.data?.data?.recordset[0].employeeUf ? getEmployeeSave?.data?.data?.recordset[0].employeeUf : '')
                    setEmployeeCity(getEmployeeSave?.data?.data?.recordset[0].employeeCity ? getEmployeeSave?.data?.data?.recordset[0].employeeCity : 0)
                    setSelectedWorkCardType(getEmployeeSave?.data?.data?.recordset[0].wordCardType ? getEmployeeSave?.data?.data?.recordset[0].wordCardType : '0')
                    setFirstJob(getEmployeeSave?.data?.data?.recordset[0].firstJob ? getEmployeeSave?.data?.data?.recordset[0].firstJob : false)
                    setWorkCard(getEmployeeSave?.data?.data?.recordset[0].workCard ? getEmployeeSave?.data?.data?.recordset[0].workCard : '')
                    setWorkCardSerie(getEmployeeSave?.data?.data?.recordset[0].workCardSerie ? getEmployeeSave?.data?.data?.recordset[0].workCardSerie : '')
                    setWorkCardEmission(getEmployeeSave?.data?.data?.recordset[0].workCardEmission ? getEmployeeSave?.data?.data?.recordset[0].workCardEmission.split('T')[0] : '')
                    setWorkCardUf(getEmployeeSave?.data?.data?.recordset[0].workCardUf ? getEmployeeSave?.data?.data?.recordset[0].workCardUf : -1)
                    setCpf(getEmployeeSave?.data?.data?.recordset[0].cpf ? getEmployeeSave?.data?.data?.recordset[0].cpf : '')
                    setPis(getEmployeeSave?.data?.data?.recordset[0].pis ? getEmployeeSave?.data?.data?.recordset[0].pis : '')
                    setEmissionPis(getEmployeeSave?.data?.data?.recordset[0].emissionPis ? getEmployeeSave?.data?.data?.recordset[0].emissionPis.split('T')[0] : '')
                    setRg(getEmployeeSave?.data?.data?.recordset[0].rg ? getEmployeeSave?.data?.data?.recordset[0].rg : '')
                    setSendingAgencyRg(getEmployeeSave?.data?.data?.recordset[0].sendingAgencyRG ? getEmployeeSave?.data?.data?.recordset[0].sendingAgencyRG : '')
                    setEmissionRg(getEmployeeSave?.data?.data?.recordset[0].EmissionRG ? getEmployeeSave?.data?.data?.recordset[0].EmissionRG.split('T')[0] : '')
                    setRgUf(getEmployeeSave?.data?.data?.recordset[0].rgUf ? getEmployeeSave?.data?.data?.recordset[0].rgUf : '')
                    setVoterRegistration(getEmployeeSave?.data?.data?.recordset[0].voterRegistration ? getEmployeeSave?.data?.data?.recordset[0].voterRegistration : '')
                    setVoterRegistrationZone(getEmployeeSave?.data?.data?.recordset[0].voterRegistrationZone ? getEmployeeSave?.data?.data?.recordset[0].voterRegistrationZone : '')
                    setVoterRegistrationSection(getEmployeeSave?.data?.data?.recordset[0].voterRegistrationSection ? getEmployeeSave?.data?.data?.recordset[0].voterRegistrationSection : '')
                    setCnhExists(getEmployeeSave?.data?.data?.recordset[0].cnh ? 'true' : '')
                    setCnh(getEmployeeSave?.data?.data?.recordset[0].cnh ? getEmployeeSave?.data?.data?.recordset[0].cnh : '')
                    setCnhIssuer(getEmployeeSave?.data?.data?.recordset[0].cnhIssuer ? getEmployeeSave?.data?.data?.recordset[0].cnhIssuer : '')
                    setCnhCategory(getEmployeeSave?.data?.data?.recordset[0].cnhCategory ? getEmployeeSave?.data?.data?.recordset[0].cnhCategory : '')
                    setCnhValidity(getEmployeeSave?.data?.data?.recordset[0].cnhValidity ? getEmployeeSave?.data?.data?.recordset[0].cnhValidity.split('T')[0] : '')
                    setCnhUf(getEmployeeSave?.data?.data?.recordset[0].cnhUf ? getEmployeeSave?.data?.data?.recordset[0].cnhUf : '')
                    setCnhFirstDate(getEmployeeSave?.data?.data?.recordset[0].cnhFirstDate ? getEmployeeSave?.data?.data?.recordset[0].cnhFirstDate.split('T')[0] : '')
                    setCnhDispatchDate(getEmployeeSave?.data?.data?.recordset[0].cnhDispatchDate ? getEmployeeSave?.data?.data?.recordset[0].cnhDispatchDate.split('T')[0] : '')
                    setMilitaryServiceCard(getEmployeeSave?.data?.data?.recordset[0].militaryServiceCard ? getEmployeeSave?.data?.data?.recordset[0].militaryServiceCard : '')
                    setMilitaryServiceCardUf(getEmployeeSave?.data?.data?.recordset[0].militaryServiceCardUf ? getEmployeeSave?.data?.data?.recordset[0].militaryServiceCardUf : '')
                    setDeficiency(getEmployeeSave?.data?.data?.recordset[0].deficiency ? getEmployeeSave?.data?.data?.recordset[0].deficiency : false)
                    setDeficiencyType(getEmployeeSave?.data?.data?.recordset[0].deficiencyType ? getEmployeeSave?.data?.data?.recordset[0].deficiencyType : -1)
                    setDeficiencyRehabilitatedInss(getEmployeeSave?.data?.data?.recordset[0].deficiencyRehabilitatedInss ? getEmployeeSave?.data?.data?.recordset[0].deficiencyRehabilitatedInss : false)
                    setDeficiencyPcdQuote(getEmployeeSave?.data?.data?.recordset[0].deficiencyPcdQuote ? getEmployeeSave?.data?.data?.recordset[0].deficiencyPcdQuote : false)
                    setEmployeeRace(getEmployeeSave?.data?.data?.recordset[0].employeeRace ? getEmployeeSave?.data?.data?.recordset[0].employeeRace : '')
                    setAddressCountry(getEmployeeSave?.data?.data?.recordset[0].addressCountry ? getEmployeeSave?.data?.data?.recordset[0].addressCountry : -1)
                    setAddressCep(getEmployeeSave?.data?.data?.recordset[0].addressCep ? getEmployeeSave?.data?.data?.recordset[0].addressCep : '')
                    setAddressState(getEmployeeSave?.data?.data?.recordset[0].addressState ? getEmployeeSave?.data?.data?.recordset[0].addressState : '')
                    setAddressType(getEmployeeSave?.data?.data?.recordset[0].addressType ? getEmployeeSave?.data?.data?.recordset[0].addressType : 'R')
                    setAddressCity(getEmployeeSave?.data?.data?.recordset[0].addressCity ? getEmployeeSave?.data?.data?.recordset[0].addressCity : -1)
                    setAddressDistrict(getEmployeeSave?.data?.data?.recordset[0].addressDistrict ? getEmployeeSave?.data?.data?.recordset[0].addressDistrict : '')
                    setAddressRoad(getEmployeeSave?.data?.data?.recordset[0].addressRoad ? getEmployeeSave?.data?.data?.recordset[0].addressRoad : '')
                    setAddressNumber(getEmployeeSave?.data?.data?.recordset[0].addressNumber ? getEmployeeSave?.data?.data?.recordset[0].addressNumber : '')
                    setAddressComplement(getEmployeeSave?.data?.data?.recordset[0].addressComplement ? getEmployeeSave?.data?.data?.recordset[0].addressComplement : '')
                    setPhoneDdd(getEmployeeSave?.data?.data?.recordset[0].phoneDdd ? getEmployeeSave?.data?.data?.recordset[0].phoneDdd : '')
                    setPhoneNumber(getEmployeeSave?.data?.data?.recordset[0].phoneNumber ? getEmployeeSave?.data?.data?.recordset[0].phoneNumber : '')
                    setEmployeeEmail(getEmployeeSave?.data?.data?.recordset[0].employeeEmail ? getEmployeeSave?.data?.data?.recordset[0].employeeEmail : '')
                    setEmployeeEmailCorporate(getEmployeeSave?.data?.data?.recordset[0].employeeEmailCorporate ? getEmployeeSave?.data?.data?.recordset[0].employeeEmailCorporate : '')
                    setDateEmploymentExamination(getEmployeeSave?.data?.data?.recordset[0].dateEmploymentExamination ? getEmployeeSave?.data?.data?.recordset[0].dateEmploymentExamination.split('T')[0] : '')
                    setFatherName(getEmployeeSave?.data?.data?.recordset[0].fatherName ? getEmployeeSave?.data?.data?.recordset[0].fatherName : '')
                    setMotherName(getEmployeeSave?.data?.data?.recordset[0].motherName ? getEmployeeSave?.data?.data?.recordset[0].motherName : '')
                    setForeignResidenceTime(getEmployeeSave?.data?.data?.recordset[0].foreignResidenceTime ? getEmployeeSave?.data?.data?.recordset[0].foreignResidenceTime : '')
                    setForeignFinishResidence(getEmployeeSave?.data?.data?.recordset[0].foreignFinishResidence ? getEmployeeSave?.data?.data?.recordset[0].foreignFinishResidence.split('T')[0] : '')
                    setForeignCondition(getEmployeeSave?.data?.data?.recordset[0].foreignCondition ? getEmployeeSave?.data?.data?.recordset[0].foreignCondition : '')
                    setForeignArrivalDate(getEmployeeSave?.data?.data?.recordset[0].foreignArrivalDate ? getEmployeeSave?.data?.data?.recordset[0].foreignArrivalDate?.split('T')[0] : '')
                    setForeignRegistry(getEmployeeSave?.data?.data?.recordset[0].foreignRegistry ? getEmployeeSave?.data?.data?.recordset[0].foreignRegistry : '')
                    setForeignDateExpRegistry(getEmployeeSave?.data?.data?.recordset[0].foreignDateExpRegistry ? getEmployeeSave?.data?.data?.recordset[0].foreignDateExpRegistry.split('T')[0] : '')
                    setForeignVisaValidity(getEmployeeSave?.data?.data?.recordset[0].foreignVisaValidity ? getEmployeeSave?.data?.data?.recordset[0].foreignVisaValidity.split('T')[0] : '')
                    setForeignDateValidityCTPS(getEmployeeSave?.data?.data?.recordset[0].foreignDateValidityCTPS ? getEmployeeSave?.data?.data?.recordset[0].foreignDateValidityCTPS.split('T')[0] : '')
                    setForeignCasWithBrazilian(getEmployeeSave?.data?.data?.recordset[0].foreignCasWithBrazilian ? getEmployeeSave?.data?.data?.recordset[0].foreignCasWithBrazilian : '')
                    setForeignBrazilianChildren(getEmployeeSave?.data?.data?.recordset[0].foreignBrazilianChildren ? getEmployeeSave?.data?.data?.recordset[0].foreignBrazilianChildren : '')
                    const dependents = await api.get(`api/v1/employeesSave/company/${company}/branch/${branch}/save_id/${save_id}/dependents`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setDependents(dependents.data.data.map((dependent) => {
                        return {
                            ...dependent,
                            courtPension: !dependent.courtPension && false
                        }
                    }))

                } catch (error) {
                    setLoading(false)
                    return alert('Erro ao tentar realizar buscar de dados. Se o problema persistir entre em contato com o suporte.')
                }

            
            }

            employeeSaveData()
        }

    }, [user?.token])

    useEffect(() => {
        if (addressCountry != 0) {
            async function getStates() {
                setInsideLoading(true)
                try {
                    const getState = await api.get(`api/v1/geo/states/country/${addressCountry}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setInsideLoading(false)
                    return setStates(getState.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Estados, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [addressCountry])

    useEffect(() => {
        if (addressCountry != 0 && addressState != '' && addressState != 0) {
            async function getCities() {
                setInsideLoading(true)
                try {

                    const getCities = await api.get(`api/v1/geo/cities/country/${addressCountry}/state/${addressState}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setInsideLoading(false)
                    return setCities(getCities.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getCities()
        }
    }, [addressState])

    useEffect(() => {
        if (addressState != '' && addressState != 0 && addressCity != 0 && addressCity != '') {
            async function getDistricts() {
                setInsideLoading(true)
                try {

                    const getDistricts = await api.get(`api/v1/geo/districts/country/${addressCountry}/state/${addressState}/city/${addressCity}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setInsideLoading(false)
                    return setDistricts(getDistricts.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getDistricts()
        }
    }, [addressCity])

    useEffect(() => {
        if (nationality) {
            async function getStates() {
                try {
                    setInsideLoading(true)
                    const getNationalitiesState = await api.get(`api/v1/geo/states/nationality/${nationality}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setInsideLoading(false)
                    return setNationalitiesState(getNationalitiesState.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Estados, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [nationality])

    useEffect(() => {
        if (nationality && employeeUf) {
            async function getStates() {
                try {
                    setInsideLoading(true)
                    const getNationalitiesStateCities = await api.get(`api/v1/geo/cities/nationality/${nationality}/state/${employeeUf}`, {
                        headers: {
                            authorization: user?.token
                        }
                    })
                    setInsideLoading(false)
                    return setNationalitiesStateCities(getNationalitiesStateCities.data.data)
                } catch (err) {
                    setInsideLoading(false)
                    return alert('Erro ao tentar buscar Cidades, se o problema persistir entre em contato com o suporte.')
                }
            }
            getStates()
        }
    }, [employeeUf])

    function contractDataForm() {

        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription">
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='company'>Empresa</label>
                    <input type="text" id='company' value={companyName} className="bigInput" disabled />
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='company'>Empresa</label>
                    <input type="text" id='company' value={companyName} className="" disabled />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label htmlFor='branch'>Filial</label>
                    <input type="text" id='branch' value={branchCnpj} className="bigInput" disabled />
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='branch'>Filial</label>
                    <input type="text" id='branch' value={branchCnpj} className="" disabled />
                </div>
                <div className='hidden lg:flex lg:flex-col'>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor='employeeName'>Nome do Funcionário *</label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeName' value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} className="bigInput" maxLength={255}/>
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['employeeName'] && employeeName == '' ? 'red' : '#464545' }} htmlFor='employeeName'>Nome do Funcionário *</label>
                    <input style={{ border: missingRequiredFields['employeeName'] && employeeName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeName' value={employeeName} onChange={(e) => setEmployeeName(e.target.value)} maxLength={255}/>
                </div>
                <div>
                    <label style={{ color: ((missingRequiredFields['admissionDate'] && admissionDate == '') || missingRequiredFields['admissionDate'] && invalidAdmissionDate) ? 'red' : '#464545' }} htmlFor="admissionDate">{invalidAdmissionDate ? 'Data é menor do que a atual' : "Data Admissão *"}</label>
                    <input style={{ border: ((missingRequiredFields['admissionDate'] && admissionDate == '') || missingRequiredFields['admissionDate'] && invalidAdmissionDate) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='admissionDate' value={admissionDate} onChange={(e) => { setAdmissionDate(e.target.value)}} className="smallInput" />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['typeBusinessLink'] && typeBusinessLink == 0 ? 'red' : '#464545' }} htmlFor="typeBusinessLink">Tipo Vínculo *</label>
                    <select style={{ border: missingRequiredFields['typeBusinessLink'] && typeBusinessLink == 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                        id="typeBusinessLink" 
                        value={typeBusinessLink} 
                        onChange={(e) => {
                            // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                            if (e.target.value == '11'){
                                setTypeBusinessLink(parseInt(e.target.value))
                                setIsIntermittent(true)
                            } else {
                                setIsIntermittent(false)
                                setTypeBusinessLink(parseInt(e.target.value))
                            }

                        }}
                    >
                        <option value="">- Selecione -</option>
                        <option value="10">10 - Empregado</option>
                        <option value="55">55 - Menor Aprendiz</option>
                        <option value="90">90 - Estágiario</option>
                        <option value="11">10 - Intermitente</option>
                        <option value="49">49 - Doméstica</option>
                        <option value="80">80 - Pró Labore</option>
                        <option value="60">60 - Determinado - Lei 9.601/98</option>
                    </select>
                </div>
                {
                    typeBusinessLink == '90' &&
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeInsurancePolicy'] && traineeInsurancePolicy == '' ? 'red' : '#464545' }} htmlFor="traineeInsurancePolicy">Apólice de Seguro *</label>
                            <Input
                                id={'traineeInsurancePolicy'}
                                style={{ border: missingRequiredFields['traineeInsurancePolicy'] && traineeInsurancePolicy == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={30}                           
                                value={traineeInsurancePolicy ? traineeInsurancePolicy : ''}
                                onChange={(e) => {
                                    setTraineeInsurancePolicy(e.target.value)
                                }}        
                                width={80}                                
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEndForecast'] && traineeEndForecast == '' ? 'red' : '#464545' }} htmlFor="traineeEndForecast">Término do Estágio *</label>
                            <input 
                                style={{ border: missingRequiredFields['traineeEndForecast'] && traineeEndForecast == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='traineeEndForecast' 
                                value={traineeEndForecast} 
                                onChange={(e) => { 
                                    setTraineeEndForecast(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEducationalInstitution'] && traineeEducationalInstitution == '' ? 'red' : '#464545' }} htmlFor="traineeEducationalInstitution">Instituição de Ensino *</label>                            
                            <Input
                                id={'traineeEducationalInstitution'} 
                                style={{ border: missingRequiredFields['traineeEducationalInstitution'] && traineeEducationalInstitution == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}   
                                charLimit={255}                            
                                value={traineeEducationalInstitution ? traineeEducationalInstitution : ''}
                                onChange={(e) => {
                                    setTraineeEducationalInstitution(e.target.value)
                                }}
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeCodSupervisor'] && traineeCodSupervisor == '' ? 'red' : '#464545' }} htmlFor="traineeCodSupervisor">Supervisor *</label>
                            <Input
                                id={'traineeCodSupervisor'} 
                                style={{ border: missingRequiredFields['traineeCodSupervisor'] && traineeCodSupervisor == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}   
                                charLimit={255}                            
                                value={traineeCodSupervisor ? traineeCodSupervisor : ''}
                                onChange={(e) => {
                                    setTraineeCodSupervisor(e.target.value)
                                }}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeOrigin'] && traineeOrigin == '' ? 'red' : '#464545' }} htmlFor="typeApuration">Natureza do Estágio *</label>
                            <select style={{ border: missingRequiredFields['traineeOrigin'] && traineeOrigin == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="traineeOrigin" value={traineeOrigin} onChange={(e) => setTraineeOrigin(e.target.value)}>
                                <option value="0">- Selecione -</option>
                                <option value="O">Obrigatório</option>
                                <option value="N">Não Obrigatório</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['traineeEducationLevel'] && traineeEducationLevel == 0 ? 'red' : '#464545' }} htmlFor="typeApuration">Nível do Estágio *</label>
                            <select style={{ border: missingRequiredFields['traineeEducationLevel'] && traineeEducationLevel == 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="traineeEducationLevel" value={traineeEducationLevel} onChange={(e) => setTraineeEducationLevel(parseInt(e.target.value))}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Fundamental</option>
                                <option value="2">Médio</option>
                                <option value="3">Formação Profissional</option>
                                <option value="4">Superior</option>
                                <option value="8">Especial</option>
                                <option value="9">Mãe social. (Lei 7644, de 1987)</option>
                            </select>
                        </div>
                    </>
                }
                {
                    typeBusinessLink == '55' ?
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['apprenticeInstitutionName'] && apprenticeInstitutionName == '' ? 'red' : '#464545' }} htmlFor="apprenticeInstitutionName">Nome da Instituição do Curso *</label>
                            <Input
                                id={'apprenticeInstitutionName'}
                                style={{ border: missingRequiredFields['apprenticeInstitutionName'] && apprenticeInstitutionName == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={100}                           
                                value={apprenticeInstitutionName ? apprenticeInstitutionName : ''}
                                onChange={(e) => {
                                    setApprenticeInstitutionName(e.target.value)
                                }}        
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['apprenticeInstitutionCNPJ'] && apprenticeInstitutionCNPJ == '' ? 'red' : '#464545' }} htmlFor="apprenticeInstitutionCNPJ">CNPJ da Instituição do Curso *</label>
                            <Input
                                id={'apprenticeInstitutionCNPJ'}
                                style={{ border: missingRequiredFields['apprenticeInstitutionCNPJ'] && apprenticeInstitutionCNPJ == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={30}                           
                                value={apprenticeInstitutionCNPJ ? cnpjMask(apprenticeInstitutionCNPJ) : ''}
                                onChange={(e) => {
                                    setApprenticeInstitutionCNPJ(cnpjMask(e.target.value))
                                }}        
                                width={80}
                            />
                        </div>
                        <div>
                            <label style={{ color: ((missingRequiredFields['apprenticeDateFinish'] && apprenticeDateFinish == '') || missingRequiredFields['apprenticeDateFinish']) ? 'red' : '#464545' }} htmlFor="apprenticeDateFinish">Data do Término Previsto do Contrato *</label>
                            <input style={{ border: ((missingRequiredFields['apprenticeDateFinish'] && apprenticeDateFinish == '') || missingRequiredFields['apprenticeDateFinish']) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='apprenticeDateFinish' value={apprenticeDateFinish} onChange={(e) => { setApprenticeDateFinish(e.target.value)}} className="smallInput" />
                        </div>
                    </>
                    : 
                    <></>
                }
                <div>
                    <label style={{ color: missingRequiredFields['typeApuration'] && typeApuration == 0 ? 'red' : '#464545' }} htmlFor="typeApuration">Tipo Apuração Ponto *</label>
                    <select style={{ border: missingRequiredFields['typeApuration'] && typeApuration == 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="typeApuration" value={typeApuration} onChange={(e) => setTypeApuration(parseInt(e.target.value))}>
                        <option value="0">- Selecione -</option>
                        <option value="1">Normal</option>
                        <option value="5">Sem Controle de Ponto e Refeitório</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeLaborUnion'] && employeeLaborUnion == 0 ? 'red' : '#464545' }} htmlFor="employeeLaborUnion">Funcionário Sindicalizado *</label>
                    <select style={{ border: missingRequiredFields['employeeLaborUnion'] && employeeLaborUnion == 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeLaborUnion" value={employeeLaborUnion} onChange={(e) => setEmployeeLaborUnion((e.target.value == 'true'))}>
                        <option value="">- Selecione -</option>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                <div>
                    {
                        employeeLaborUnion == true
                            ?
                            <>
                                <div className='hidden lg:flex lg:flex-col lg:justify-center lg:h-7'>
                                    <label style={{ color: missingRequiredFields['laborUnion'] && laborUnion == '' ? 'red' : '#464545' }} htmlFor="laborUnion">Sindicato *</label>
                                    <input style={{ border: missingRequiredFields['laborUnion'] && laborUnion == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='laborUnion' value={laborUnion} onChange={(e) => setLaborUnion(e.target.value)} className="mediumInput" />
                                </div>
                                {/* Mobile */}
                                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                                    <label style={{ color: missingRequiredFields['laborUnion'] && laborUnion == '' ? 'red' : '#464545' }} htmlFor="laborUnion">Sindicato *</label>
                                    <input style={{ border: missingRequiredFields['laborUnion'] && laborUnion == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='laborUnion' value={laborUnion} onChange={(e) => setLaborUnion(e.target.value)}/>
                                </div>
                            </>
                            :
                            <>
                                <div className='hidden lg:flex lg:flex-col lg:justify-center lg:h-7'>
                                    <label htmlFor="laborUnion">Sindicato</label>
                                    <input type="text" id='laborUnion' value='Não aplicável' className="mediumInput" disabled />
                                </div>
                                {/* Mobile */}
                                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                                    <label htmlFor="laborUnion">Sindicato</label>
                                    <input type="text" id='laborUnion' value='Não aplicável' disabled />
                                </div>
                            </>
                    }
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['employeePosition'] && employeePosition == '' ? 'red' : '#464545' }} htmlFor="employeePosition">Cargo *</label>
                    <input style={{ border: missingRequiredFields['employeePosition'] && employeePosition == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeePosition' value={employeePosition} onChange={(e) => setEmployeePosition(e.target.value)} className="mediumInput" />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['employeePosition'] && employeePosition == '' ? 'red' : '#464545' }} htmlFor="employeePosition">Cargo *</label>
                    <input style={{ border: missingRequiredFields['employeePosition'] && employeePosition == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeePosition' value={employeePosition} onChange={(e) => setEmployeePosition(e.target.value)}/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeSchedule'] ? 'red' : '#464545' }} htmlFor="employeeSchedule">Escala de Trabalho *</label>
                    {
                        employeeScheduleType == '' || employeeScheduleFriday == '' || employeeScheduleMonday == '' || employeeScheduleSaturday == '' ||
                            employeeScheduleSunday == '' || employeeScheduleThursday == '' || employeeScheduleTuesday == '' || employeeScheduleWednesday == '' ?
                            <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput notCompleted" value="Informar" /> :
                            <input type="button" id='employeeSchedule' onClick={() => setShow(true)} className="soSmallInput" value="Informado!" />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeSalary'] && ((employeeSalary == '' || employeeSalary <= 0) && typeSalary != 4) ? 'red' : '#464545' }} htmlFor="employeeSalary">{typeSalary != 4 ? 'Salário Base *' : 'Salário Base'} </label>
                    <input style={{ border: missingRequiredFields['employeeSalary'] && ((employeeSalary == '' || employeeSalary <= 0) && typeSalary != 4) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="number" id='employeeSalary' value={employeeSalary} onChange={(e) => { e.target.value !== '' ? setEmployeeSalary(parseFloat(e.target.value)) : setEmployeeSalary(parseFloat(e.target.value)) }} className="soSmallInput"/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['typeSalary'] && typeSalary < 0 ? 'red' : '#464545' }} htmlFor="typeSalary">Salário Tipo *</label>
                    <select style={{ border: missingRequiredFields['typeSalary'] && typeSalary < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="typeSalary" value={typeSalary} onChange={(e) => setTypeSalary(parseInt(e.target.value))}>
                        <option value="-1">- Selecione -</option>
                        <option value="1">Mensalista</option>
                        <option value="2">Horista</option>
                        <option value="4">Comissionado</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="gratificationFunction">Gratificação de Função</label>
                    <select id="gratificationFunction" value={gratificationFunction} onChange={(e) => setGratificationFunction(e?.target?.value)}>
                        <option value="">- Selecione -</option>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeLocal'] && employeeLocal == '' ? 'red' : '#464545' }} htmlFor="employeeLocal">Local/Setor *</label>
                    <input style={{ border: missingRequiredFields['employeeLocal'] && employeeLocal == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id="employeeLocal" value={employeeLocal} onChange={(e) => setEmployeeLocal(e.target.value)} className='smallInput' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeCCU'] && employeeCCU == '' ? 'red' : '#464545' }} htmlFor="employeeCCU">Centro de Custos</label>
                    <input type="text" id="employeeCCU" value={employeeCCU} onChange={(e) => setEmployeeCCU(e.target.value)} className='smallInput' />
                </div>
                {
                    companyConfig?.useNatureExpenses == 'S' &&
                    <div>
                        <label style={{ color: missingRequiredFields['natureExpense'] ? 'red' : '#464545' }} htmlFor="natureExpense">Natureza de despesas *</label>
                        <select style={{ border: missingRequiredFields['natureExpense'] ? '1px solid #e2a3a7' : '1px solid #999999' }} id="natureExpense" value={natureExpense} onChange={(e) => setNatureExpense(e?.target?.value)}>
                            <option value="0">- Selecione -</option>
                            <option value="1">1 - Mão de Obra Direta</option>
                            <option value="2">2 - Mão de Obra Indireta</option>
                            <option value="3">3 - ADMINISTRATIVO</option>
                            <option value="4">4 - VENDAS</option>
                            <option value="5">5 - GERENCIA</option>
                            <option value="6">6 - ADM</option>
                            <option value="7">7 - CUSTOS</option>
                        </select>
                    </div>
                }
                <div>
                    <label style={{ color: missingRequiredFields['employeeWorkplace'] && employeeWorkplace == '' ? 'red' : '#464545' }} htmlFor="employeeWorkplace">Posto de Trabalho</label>
                    <input type="text" id="employeeWorkplace" value={employeeWorkplace} onChange={(e) => setEmployeeWorkplace(e.target.value)} className='smallInput' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeVT'] && employeeVT == '0' ? 'red' : '#464545' }} htmlFor="employeeVT">VT *</label>
                    <select style={{ border: missingRequiredFields['employeeVT'] && employeeVT == '0' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeVT" value={employeeVT} onChange={(e) => setEmployeeVT((e.target.value == 'true'))}>
                        <option value="">- Selecione -</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div>
                    {
                        employeeVT == 1
                            ? (
                                <>
                                    <label style={{ color: missingRequiredFields['employeeValueVT'] && (isNaN(employeeValueVT) || employeeValueVT <= 0) ? 'red' : '#464545' }} htmlFor="employeeValueVT">Quantidade por Dia *</label>
                                    <input style={{ border: missingRequiredFields['employeeValueVT'] && (isNaN(employeeValueVT) || employeeValueVT <= 0) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="number" id="employeeValueVT" value={employeeValueVT} onChange={(e) => setEmployeeValueVT(parseFloat(e.target.value))} className='soSmallInput' />
                                </>
                            )
                            : (
                                <>
                                    <label htmlFor="employeeValueVT">Quantidade por Dia</label>
                                    <input type="text" id="employeeValueVT" value='Não aplicável' className='soSmallInput' disabled />
                                </>
                            )
                    }
                </div>
                <div>
                    <label htmlFor="advanceSalary">Adiantamento Salarial *</label>
                    <select id="advanceSalary" value={advanceSalary} onChange={(e) => setAdvanceSalary((e.target.value == 'true'))}>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div>
                    {
                        !advanceSalary || advanceSalary == 'false' ? (
                            <>
                                <label htmlFor="advanceSalaryPorcentage">Porcentagem</label>
                                <input type="text" id="advanceSalaryPorcentage" value='Não aplicável' className='soSmallInput' disabled />
                            </>
                        ) : (
                            <>
                                <label style={{ color: missingRequiredFields['advanceSalaryPorcentage'] && (isNaN(advanceSalaryPorcentage) || advanceSalaryPorcentage <= 0) ? 'red' : '#464545' }} htmlFor="advanceSalaryPorcentage">Porcentagem *</label>
                                <input style={{ border: missingRequiredFields['advanceSalaryPorcentage'] && (isNaN(advanceSalaryPorcentage) || advanceSalaryPorcentage <= 0) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="number" id="advanceSalaryPorcentage" value={advanceSalaryPorcentage} onChange={(e) => setAdvanceSalaryPorcentage(parseInt(e.target.value))} className='soSmallInput' />
                            </>
                        )
                    }
                </div>
                <div>
                    <label htmlFor="advanceMonthAdmission">Recebe Adto Mês Admissão</label>
                    <select id="advanceMonthAdmission" value={advanceMonthAdmission ? advanceMonthAdmission : ''} onChange={(e) => setAdvanceMonthAdmission(e.target.value)} disabled={!advanceSalary || advanceSalary == 'false' ? true : false}>
                        <option value="1">Sim</option>
                        <option value="">Não</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeExperience'] && employeeExperience < 0 ? 'red' : '#464545' }} htmlFor="employeeExperience">Experiência *</label>
                    <select style={{ border: missingRequiredFields['employeeExperience'] && employeeExperience < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id="employeeExperience" value={employeeExperience} onChange={(e) => setEmployeeExperience(parseInt(e.target.value))}>
                        <option value="-1">- Selecione -</option>
                        <option value="1">30 + 60</option>
                        <option value="2">45 + 45</option>
                        <option value="3">60 + 30</option>
                        <option value="4">30 + 30</option>
                        <option value="6">30 + 45</option>
                        <option value="5">Não será aplicado</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="lifeInsurance">Seguro de Vida *</label>
                    <select type="text" id="lifeInsurance" value={lifeInsurance} onChange={(e) => setLifeInsurance((e.target.value == 'true'))}>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="insalubrity">Insalubridade</label>
                    <select type="text" id="insalubrity" value={insalubrity} onChange={(e) => setInsalubrity((e.target.value === 'true'))} className='soSmallInputLittle'>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    insalubrity ?
                    <div>
                        <label htmlFor="insalubrityPercentage">Porcentagem Insalubridade</label>
                        <input type="number" id="insalubrityPercentage" value={insalubrityPercentage} onChange={(e) => setInsalubrityPercentage(parseInt(e.target.value))} className='soSmallInput' />
                    </div>
                    :
                    <div>
                        <label htmlFor="insalubrityPercentage">Porcentagem Insalubridade</label>
                        <input type="text" id="insalubrityPercentage" value='Não aplicável' className='soSmallInput' disabled />
                    </div>
                }
                <div>
                    <label htmlFor="periculosity">Periculosidade</label>
                    <select type="text" id="periculosity" value={periculosity} onChange={(e) => setPericulosity((e.target.value) === 'true')} className='soSmallInputLittle'>
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    periculosity ?
                    <div>
                        <label htmlFor="periculosityPercentage">Porcentagem Periculosidade</label>
                        <input type="number" id="periculosityPercentage" value={periculosityPercentage} onChange={(e) => setPericulosityPercentage(parseInt(e.target.value))} className='soSmallInput' />
                    </div>
                    :
                    <div>
                        <label htmlFor="periculosityPercentage">Porcentagem Periculosidade</label>
                        <input type="text" id="periculosityPercentage" value='Não aplicável' className='soSmallInput' disabled />
                    </div>
                }
                <div>
                    <label style={{ color: missingRequiredFields['paymentPeriod'] && paymentPeriod == '0' ? 'red' : '#464545' }} htmlFor="paymentPeriod">Período de Pagmento *</label>
                    <select style={{ border: missingRequiredFields['paymentPeriod'] && paymentPeriod == '0' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="paymentPeriod" value={paymentPeriod} onChange={(e) => setPaymentPeriod(e.target.value)}>
                        <option value="0">- Selecione -</option>
                        <option value="M">Mensal</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['paymentMethod'] && paymentMethod == '' ? 'red' : '#464545' }} htmlFor="paymentMethod">Forma de Pagamento *</label>
                    <select style={{ border: missingRequiredFields['paymentMethod'] && paymentMethod == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="paymentMethod" value={paymentMethod} onChange={(e) => {
                        setPaymentMethod(e.target.value);
                    }}>
                        <option value="">- Selecione -</option>
                        <option value="D">Dinheiro</option>
                        <option value="R">Relação Bancária</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBank'] && paymentMethod == 'R' && employeeBank < 0 ? 'red' : '#464545' }} htmlFor="employeeBank">Banco</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <select style={{ border: missingRequiredFields['employeeBank'] && employeeBank < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBank" value={employeeBank} onChange={(e) => {
                                    setEmployeBank(parseInt(e.target.value))
                                }}>
                                    <option value="0">- Selecione -</option>
                                    {
                                        banks.map(bank => {
                                            return <option value={bank.bank_id}>{bank.bank_name.toUpperCase()} - {bank.bank_id} </option>
                                        })
                                    }
                                </select>
                            )
                            :
                            (
                                <input type="text" id="employeeBank" value='Não aplicável' className='smallInput' disabled />
                            )
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAgencies'] && paymentMethod == 'R' && !employeBankAgencie ? 'red' : '#464545' }} htmlFor="employeeBankAgencies">Agência</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <Input
                                    style={{ border: missingRequiredFields['employeeBankAgencies'] && !employeBankAgencie ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                    id="employeeBankAgencies"
                                    value={employeBankAgencie} 
                                    onChange={(e) => {
                                        setEmployeBankAgencie(justNumber(e.target.value))
                                    }}
                                    width='20'
                                    charLimit={4}
                                    className='soSmallInputLittle'
                                />
                            )
                            :
                            (
                                <input type="text" id="employeeBankAgencies" value='Não aplicável' className='smallInput' disabled />
                            )
                    }
                </div>
                <div>
                    <label htmlFor="digitAgency">Dígito Agência</label>
                    {
                        paymentMethod == 'R' ?
                        <Input
                            id="digitAgency"
                            value={digitAgency ? digitAgency : ''} 
                            onChange={(e) => {
                                setDigitAgency(justNumber(e.target.value))
                            }}
                            width='20'
                            charLimit={1}
                            className='soSmallInputLittle'
                        />
                        :
                        <input type="text" id="digitAgency" value='Não aplicável' className='smallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccountType'] && paymentMethod == 'R' && employeeBankAccountType < 0 ? 'red' : '#464545' }} htmlFor="employeeBankAccountType">Tipo de Conta</label>
                    {
                        paymentMethod == 'R'
                            ?
                            (
                                <select style={{ border: missingRequiredFields['employeeBankAccountType'] && employeeBankAccountType < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccountType" value={employeeBankAccountType} onChange={(e) => setEmployeeBankAccountType(parseInt(e.target.value))} className='smallInput'>
                                    <option value="0">- Selecione -</option>
                                    <option value="1">Conta Corrente</option>
                                    <option value="2">Conta Poupança</option>
                                    <option value="3">Conta Salário</option>
                                    <option value="9">Outros</option>
                                </select>
                            )
                            : <input type="text" id="employeeBankAccountType" value='Não aplicável' className='smallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccount'] && (isNaN(employeeBankAccount) || employeeBankAccount <= 0) ? 'red' : '#464545' }} htmlFor="employeeBankAccount">Nº Conta</label>
                    {
                        paymentMethod == 'R'
                            ? <input style={{ border: missingRequiredFields['employeeBankAccount'] && (isNaN(employeeBankAccount) || employeeBankAccount <= 0) ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccount" value={employeeBankAccount} onChange={(e) => setEmployeeBankAccount(e.target.value)} className='soSmallInput' maxlength='12' />
                            : <input type="text" id="employeeBankAccount" value='Não aplicável' className='soSmallInput' disabled />
                    }
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeBankAccountDigit'] && paymentMethod == 'R' && employeeBankAccountDigit == '' ? 'red' : '#464545' }} htmlFor="employeeBankAccountDigit">Dígito Conta</label>
                    {
                        paymentMethod == 'R'
                            ? <input style={{ border: missingRequiredFields['employeeBankAccountDigit'] && employeeBankAccountDigit == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeBankAccountDigit" value={employeeBankAccountDigit} onChange={(e) => setEmployeeBankAccountDigit(e.target.value)} className='soSmallInputLittle' maxlength='3' />
                            : <input type="text" id="employeeBankAccount" value='Não aplicável' className='soSmallInputLittle' disabled />
                    }
                </div>
                <div className='hidden lg:flex lg:flex-col' id='onlyMobile'>
                    <label htmlFor="observation">Observação</label>
                    <textarea maxLength={255} id='observation onlyMobile' value={observation} onChange={(e) => setObservation(e.target.value)}></textarea>
                </div>
                {/* Campo apenas para exibir no PDF */}
                <div className='hidden'>
                    <p className='hidden text-sm'><h3>Observação: </h3>{observation}</p>   
                </div>
                <div className='flex lg:hidden flex-col pb-5' id='onlyMobile'>
                    <label htmlFor="observation">Observação</label>
                    <textarea maxLength={255} id='observation' className='smallInput' value={observation} onChange={(e) => setObservation(e.target.value)}></textarea>
                </div>
            </div>
        )
    }

    function employeeDataForm() {

        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-[75vh] 2xl:h-full h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription">
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='hidden sm:flex sm:flex-col'>
                    <label htmlFor='nickname'>Apelido</label>
                    <input type="text" id='nickname' value={nickname} onChange={(e) => setNickname(e.target.value)} className="bigInput" maxLength={50}/>
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label htmlFor='nickname'>Apelido</label>
                    <input type="text" id='nickname' value={nickname} onChange={(e) => setNickname(e.target.value)} maxLength={50}/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeSexualGender'] && (employeeSexualGender < 0 || employeeSexualGender == '') ? 'red' : '#464545' }} htmlFor="employeeSexualGender">Sexo *</label>
                    <select style={{ border: missingRequiredFields['employeeSexualGender'] && (employeeSexualGender < 0 || employeeSexualGender == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id="employeeSexualGender" value={employeeSexualGender} onChange={(e) => setEmployeeSexualGender(e.target.value)} >
                        <option value="-1">- Selecione -</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['civilStatus'] && civilStatus < 0 ? 'red' : '#464545' }} htmlFor="civilStatus">Estado Civil *</label>
                    <select style={{ border: missingRequiredFields['civilStatus'] && civilStatus < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="civilStatus" value={civilStatus} onChange={(e) => setCivilStatus(parseInt(e.target.value))} >
                        <option value="-1">- Selecione -</option>
                        <option value="1">1 - Solteiro</option>
                        <option value="2">2 - Casado</option>
                        <option value="3">3 - Divorciado</option>
                        <option value="4">4 - Viúvo</option>
                        <option value="5">5 - Concubinado</option>
                        <option value="6">6 - Separado</option>
                        <option value="7">7 - União Estável</option>
                        <option value="9">9 - Outros</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['levelOfEducation'] && levelOfEducation < 0 ? 'red' : '#464545' }} htmlFor="levelOfEducation">Formação *</label>
                    <select style={{ border: missingRequiredFields['levelOfEducation'] && levelOfEducation < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} id="levelOfEducation" value={levelOfEducation} onChange={(e) => setLevelOfEducation(parseInt(e.target.value))} >
                        <option value="-1">- Selecione -</option>
                        <option value="1">1 - Analfabeto</option>
                        <option value="2">2 - 4ª série incompleta</option>
                        <option value="3">3 - 4ª série completa</option>
                        <option value="4">4 - 5ª a 8ª série incompleta</option>
                        <option value="5">5 - 1º grau completo</option>
                        <option value="6">6 - 2º grau incompleto </option>
                        <option value="7">7 - 2º grau completo</option>
                        <option value="8">8 - Superior incompleto</option>
                        <option value="9">9 - Superior completo</option>
                        <option value="10">10 - Pós-graduação</option>
                        <option value="11">11 - Mestrado Completo</option>
                        <option value="12">12 - Doutorado Completo</option>
                        <option value="13">13 - Ph.D</option>
                        <option value="14">14 - 2º grau tec. incompleto</option>
                        <option value="15">15 - 2º grau tec. completo</option>
                        <option value="16">16 - Mestrado Incompleto</option>
                        <option value="17">17 - Doutorado Incompleto</option>
                        <option value="18">18 - Pós-graduação Incompleto</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: (missingRequiredFields['birthday'] && (birthday == '0' || birthday == '') || invalidBirthday) ? 'red' : '#464545' }} htmlFor="birthday">{invalidBirthday ? "Data de Nascimento inválida" : "Data de Nascimento *"}</label>
                    <input style={{ border: ((missingRequiredFields['birthday'] && (birthday == '0' || birthday == '')) || invalidBirthday) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='birthday' value={birthday ? birthday : ''} onChange={(e) => { setBirthday(e.target.value); validateBirthday(e.target.value) }} className='smallInput' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['birthday'] && (nationality < 0 || nationality == '') ? 'red' : '#464545' }} htmlFor="nationality">Nacionalidade *</label>
                    <select style={{ border: missingRequiredFields['birthday'] && (nationality < 0 || nationality == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id="nationality" value={nationality} onChange={(e) => setNationality(parseInt(e.target.value))} >
                        <option value="-1">- Selecione -</option>
                        {
                            nationalities.map(nationality => {
                                return <option value={nationality.nationality_id}>{nationality.nationality_name}</option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeUF'] && employeeUf == '' ? 'red' : '#464545' }} htmlFor="employeeUF">UF Nascimento *</label>
                    <select style={{ border: missingRequiredFields['employeeUF'] && employeeUf == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeUF' maxlength="2" value={employeeUf ? employeeUf.toUpperCase(): ''} onChange={(e) => setEmployeeUf(e.target.value)} className='smallInput' maxLength='2'>
                        <option value="">- Selecione -</option>
                        {
                            nationalitiesState.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['employeeCity'] && employeeCity == '' ? 'red' : '#464545' }} htmlFor="employeeCity">Cidade de Nascimento *</label>
                    <select style={{ border: missingRequiredFields['employeeCity'] && employeeCity == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeCity' maxlength="2" value={employeeCity} onChange={(e) => { setEmployeeCity(parseInt(e.target.value)); }} className='smallInput' maxLength='2'>
                        <option value="">- Selecione -</option>
                        {
                            nationalitiesStateCities.map(city => {
                                return <option value={city.city_id}> {city.city_name} </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    {
                        !invalidCPF ?
                            <label style={{ color: missingRequiredFields['cpf'] && (cpf == '0' || cpf == '') ? 'red' : '#464545' }} htmlFor="cpf">CPF *</label>
                            :
                            <label style={{ color: 'red', fontWeight: 'bolder' }} htmlFor="cpf">CPF inválido</label>
                    }
                    <input style={{ border: missingRequiredFields['cpf'] && (cpf == '0' || cpf == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cpf' maxlength="14" value={cpf ? cpfMask(cpf) : ''} onBlur={() => validateCPF(cpf)} onChange={(e) => wordCardType === '1' ? handleCPF(e) : setCpf(e.target.value)} className='smallInput' />
                </div>
                {/* Caso nacionalidade for estrangeiro = diferente que 10 */}
                {
                    nationality && nationality != 10 &&
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignArrivalDate'] && foreignArrivalDate == '' ? 'red' : '#464545' }} htmlFor="foreignArrivalDate">Data Chegada *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignArrivalDate'] && foreignArrivalDate == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignArrivalDate' 
                                value={foreignArrivalDate} 
                                onChange={(e) => { 
                                    setForeignArrivalDate(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignResidenceTime'] && foreignResidenceTime == '' ? 'red' : '#464545' }} htmlFor="foreignResidenceTime">Tempo de Residência *</label>
                            <select style={{ border: missingRequiredFields['foreignResidenceTime'] && foreignResidenceTime == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignResidenceTime" value={foreignResidenceTime} onChange={(e) => setForeignResidenceTime(parseInt(e.target.value))}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Prazo indeterminado</option>
                                <option value="2">Prazo determinado</option>
                            </select>
                        </div>
                        {
                            foreignResidenceTime == 2 &&
                            <div>
                                <label style={{ color: missingRequiredFields['foreignFinishResidence'] && foreignFinishResidence == '' ? 'red' : '#464545' }} htmlFor="foreignFinishResidence">Data de Término da Residência *</label>
                                <input 
                                    style={{ border: missingRequiredFields['foreignFinishResidence'] && foreignFinishResidence == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                    type="date" 
                                    id='foreignFinishResidence' 
                                    value={foreignFinishResidence} 
                                    onChange={(e) => { 
                                        setForeignFinishResidence(e.target.value)
                                    }} 
                                    className="smallInput" />
                            </div>
                        }
                        <div>
                            <label style={{ color: missingRequiredFields['foreignCondition'] && foreignCondition == '' ? 'red' : '#464545' }} htmlFor="foreignCondition">Condição Estrangeiro *</label>
                            <select style={{ border: missingRequiredFields['foreignCondition'] && foreignCondition == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignCondition" value={foreignCondition} onChange={(e) => setForeignCondition(parseInt(e.target.value))}>
                                <option value="0">- Selecione -</option>
                                <option value="1">Visto permanente</option>
                                <option value="2">Visto temporário</option>
                                <option value="3">Asilado</option>
                                <option value="4">Refugiado</option>
                                <option value="5">Solicitante de Refúgio</option>
                                <option value="6">Residente fora do Brasil</option>
                                <option value="7">Deficiente físico e com mais de 51 anos</option>
                                <option value="8">Com residência provisória e anistiado, em situação irregular</option>
                                <option value="9">Permanência no Brasil em razão de reunião familiar</option>
                                <option value="10">Beneficiado pelo acordo entre países do Mercosul</option>
                                <option value="11">Dependente de agente diplomático e/ou consular de países que mantém convênio de reciprocidade para o exercício ...</option>
                                <option value="12">Beneficiado pelo Tratado de Amizade, Cooperação e Consulta entre a República Federativa do Brasil ...</option>
                                <option value="13">Outra condição</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignRegistry'] && foreignRegistry == '' ? 'red' : '#464545' }} htmlFor="foreignRegistry">Reg.Estrangeiro *</label>
                            <Input
                                id={'foreignRegistry'}
                                style={{ border: missingRequiredFields['foreignRegistry'] && foreignRegistry == '' ? '1px solid #e2a3a7' : '1px solid #999999' }}
                                charLimit={14}                           
                                value={foreignRegistry ? foreignRegistry : ''}
                                onChange={(e) => {
                                    setForeignRegistry(e.target.value)
                                }}        
                                width={80}                                
                            />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignDateExpRegistry'] && foreignDateExpRegistry == '' ? 'red' : '#464545' }} htmlFor="foreignDateExpRegistry">Data Expedição Reg.Estrangeiro *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignDateExpRegistry'] && foreignDateExpRegistry == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignDateExpRegistry' 
                                value={foreignDateExpRegistry} 
                                onChange={(e) => { 
                                    setForeignDateExpRegistry(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignVisaValidity'] && foreignVisaValidity == '' ? 'red' : '#464545' }} htmlFor="foreignVisaValidity">Validade do Visto *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignVisaValidity'] && foreignVisaValidity == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignVisaValidity' 
                                value={foreignVisaValidity} 
                                onChange={(e) => { 
                                    setForeignVisaValidity(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignDateValidityCTPS'] && foreignDateValidityCTPS == '' ? 'red' : '#464545' }} htmlFor="foreignDateValidityCTPS">Data Validade CTPS *</label>
                            <input 
                                style={{ border: missingRequiredFields['foreignDateValidityCTPS'] && foreignDateValidityCTPS == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                                type="date" 
                                id='foreignDateValidityCTPS' 
                                value={foreignDateValidityCTPS} 
                                onChange={(e) => { 
                                    setForeignDateValidityCTPS(e.target.value)
                                }} 
                                className="smallInput" />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignCasWithBrazilian'] && foreignCasWithBrazilian == '' ? 'red' : '#464545' }} htmlFor="foreignCasWithBrazilian">Casado com Brasileiro *</label>
                            <select style={{ border: missingRequiredFields['foreignCasWithBrazilian'] && foreignCasWithBrazilian == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignCasWithBrazilian" value={foreignCasWithBrazilian} onChange={(e) => setForeignCasWithBrazilian(e.target.value)}>
                                <option value="">- Selecione -</option>
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['foreignBrazilianChildren'] && foreignBrazilianChildren == '' ? 'red' : '#464545' }} htmlFor="foreignBrazilianChildren">Filhos Brasileiros *</label>
                            <select style={{ border: missingRequiredFields['foreignBrazilianChildren'] && foreignBrazilianChildren == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="foreignBrazilianChildren" value={foreignBrazilianChildren} onChange={(e) => setForeignBrazilianChildren(e.target.value)}>
                                <option value="">- Selecione -</option>
                                <option value="S">Sim</option>
                                <option value="N">Não</option>
                            </select>
                        </div>
                    </>
                }
                <div>
                    <label>Primeiro Emprego *</label>
                    <select id='firstJob' onChange={(e) => { setFirstJob((e.target.value == 'true')) }}>
                        <option value='0'>Não</option>
                        <option value='1'>Sim</option>
                    </select>
                </div>
                <div>
                    <label>Carteira digital *</label>
                    <select id='selectedWorkCardType' onChange={(e) => { setSelectedWorkCardType(e.target.value); handleCPF() }}>
                        <option value='0' selected>Não</option>
                        <option value='1'>Sim</option>
                    </select>
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['workCard'] && (workCard == '0' || workCard == '') ? 'red' : '#464545' }} htmlFor="workCard">Carteira de Trabalho *</label>
                    <input disabled={wordCardType === '1' ? true : false} style={{ border: missingRequiredFields['workCard'] && (workCard == '0' || workCard == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='workCard' value={workCard} onChange={(e) => setWorkCard(e.target.value)} className='mediumInput' maxLength='9' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['workCard'] && (workCard == '0' || workCard == '') ? 'red' : '#464545' }} htmlFor="workCard">Carteira de Trabalho *</label>
                    <input disabled={wordCardType === '1' ? true : false} style={{ border: missingRequiredFields['workCard'] && (workCard == '0' || workCard == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='workCard' value={workCard} onChange={(e) => setWorkCard(e.target.value)} maxLength='9' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['workCardSerie'] && (workCardSerie == '0' || workCardSerie == '') ? 'red' : '#464545' }} htmlFor="workCardSerie">CTPS Serie *</label>
                    <input disabled={wordCardType === '1' ? true : false} style={{ border: missingRequiredFields['workCardSerie'] && (workCardSerie == '0' || workCardSerie == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='workCardSerie' value={workCardSerie} onChange={(e) => setWorkCardSerie(e.target.value)} className='smallInput' maxLength='5' />
                </div>
                {
                    wordCardType == '0' &&
                    <div>
                        <label style={{ color: missingRequiredFields['workCardEmission'] && (workCardEmission == '0' || workCardEmission == '') ? 'red' : '#464545' }} htmlFor="workCardEmission">CTPS Emissão</label>
                        <input style={{ border: missingRequiredFields['workCardEmission'] && (workCardEmission == '0' || workCardEmission == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='workCardEmission' value={workCardEmission} onChange={(e) => setWorkCardEmission(e.target.value)} className='smallInput' />
                    </div>
                }
                <div>
                    <label style={{ color: missingRequiredFields['workCardUF'] && (workCardUf < 0 || workCardUf == '') ? 'red' : '#464545' }} htmlFor="workCardUF">CTPS UF</label>
                    <select style={{ border: missingRequiredFields['workCardUF'] && (workCardUf < 0 || workCardUf == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id="workCardUF" value={workCardUf} onChange={(e) => setWorkCardUf(e.target.value)} >
                        <option value="-1">- Selecione -</option>
                        {
                            brazilStates.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                        <option value="xx">Estrangeiro</option>
                    </select>
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['pis'] && (pis == '0' || pis == '') ? 'red' : '#464545' }} htmlFor="pis">PIS</label>
                    <input style={{ border: missingRequiredFields['pis'] && (pis == '0' || pis == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='pis' minLength='11' maxLength="13" value={pis ? pisMask(pis) : ''} onChange={(e) => setPis(e.target.value)} className='mediumInput' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['pis'] && (pis == '0' || pis == '') ? 'red' : '#464545' }} htmlFor="pis">PIS</label>
                    <input style={{ border: missingRequiredFields['pis'] && (pis == '0' || pis == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='pis' minLength='11' maxLength="13" value={pis ? pisMask(pis) : ''} onChange={(e) => setPis(e.target.value)}/>
                </div>
                <div>
                    <label htmlFor="pisEmission">Emissão PIS</label>
                    <input type="date" id='pisEmission' value={emissionPis} onChange={(e) => setEmissionPis(e.target.value)} className='smallInput' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['RG'] && (rg == '0' || rg == '') ? 'red' : '#464545' }} htmlFor="RG">RG</label>
                    <input style={{ border: missingRequiredFields['RG'] && (rg == '0' || rg == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='RG' value={rg} onChange={(e) => setRg(e.target.value)} className='mediumInput' maxlength="16" />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['sendingAgencyRG'] && (sendingAgencyRG == '0' || sendingAgencyRG == '') && rg !== '' ? 'red' : '#464545' }} htmlFor="sendingAgencyRG">RG Orgão Expedidor</label>
                    <input style={{ border: missingRequiredFields['sendingAgencyRG'] && (sendingAgencyRG == '0' || sendingAgencyRG == '') && rg !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='sendingAgencyRG' value={sendingAgencyRG} onChange={(e) => setSendingAgencyRg(e.target.value)} className='mediumInput' maxlength="20" />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['RG'] && (rg == '0' || rg == '') ? 'red' : '#464545' }} htmlFor="RG">RG</label>
                    <input style={{ border: missingRequiredFields['RG'] && (rg == '0' || rg == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='RG' value={rg} onChange={(e) => setRg(e.target.value)} maxlength="16" />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['sendingAgencyRG'] && (sendingAgencyRG == '0' || sendingAgencyRG == '') && rg !== '' ? 'red' : '#464545' }} htmlFor="sendingAgencyRG">RG Orgão Expedidor</label>
                    <input style={{ border: missingRequiredFields['sendingAgencyRG'] && (sendingAgencyRG == '0' || sendingAgencyRG == '') && rg !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='sendingAgencyRG' value={sendingAgencyRG} onChange={(e) => setSendingAgencyRg(e.target.value)} maxlength="20" />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['emissionRG'] && (EmissionRG == '0' || EmissionRG == '') && rg !== '' ? 'red' : '#464545' }} htmlFor="emissionRG">RG Emissão</label>
                    <input style={{ border: missingRequiredFields['emissionRG'] && (EmissionRG == '0' || EmissionRG == '') && rg !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='emissionRG' value={EmissionRG} onChange={(e) => setEmissionRg(e.target.value)} className='smallInput' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['RGuf'] && (rgUf == '0' || rgUf == '') && rg !== '' ? 'red' : '#464545' }} htmlFor="RGuf">RG UF</label>
                    <select style={{ border: missingRequiredFields['RGuf'] && (rgUf == '0' || rgUf == '') && rg !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} id="RGuf" value={rgUf} onChange={(e) => setRgUf(e.target.value)} >
                        <option value="">- Selecione -</option>
                        {
                            brazilStates.map(state => {
                                return <option value={state.state_id}> {state.state_id} </option>
                            })
                        }
                    </select>
                </div>
                <div>
                    <label htmlFor="voterRegistration">Titulo de Eleitor</label>
                    <input 
                        type="number" 
                        id='voterRegistration' 
                        value={voterRegistration}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 13) {                                
                                setVoterRegistration(e.target.value)
                                handleVoterRegistration()
                            }
                        }}
                        className='smallInput'
                        maxLength={'13'}
                    />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['voterRegistrationZone'] && (voterRegistrationZone == '0' || voterRegistrationZone == '') && voterRegistration !== '' ? 'red' : '#464545' }} htmlFor="voterRegistrationZone">Titulo de Eleitor Zona</label>
                    <input 
                        style={{ border: missingRequiredFields['voterRegistrationZone'] && (voterRegistrationZone == '0' || voterRegistrationZone == '') && voterRegistration !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                        type="text" 
                        id='voterRegistrationZone' 
                        value={voterRegistrationZone} 
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 3) {
                                setVoterRegistrationZone(e.target.value)
                            }
                        }} 
                        className='smallInput'
                        maxLength='3' 
                    />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['voterRegistrationSection'] && (voterRegistrationSection == '0' || voterRegistrationSection == '') && voterRegistration !== '' ? 'red' : '#464545' }} htmlFor="voterRegistrationSection">Titulo de Eleitor Seção</label>
                    <input 
                        style={{ border: missingRequiredFields['voterRegistrationSection'] && (voterRegistrationSection == '0' || voterRegistrationSection == '') && voterRegistration !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} 
                        type="text" 
                        id='voterRegistrationSection' 
                        value={voterRegistrationSection} 
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 4) {
                                setVoterRegistrationSection(e.target.value)
                            }
                        }} 
                        className='smallInput' 
                        maxLength='4' 
                    />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['cnhExists'] && (cnhExists == '0' || cnhExists == '') && cnhExists !== '' ? 'red' : '#464545' }} htmlFor="cnhExists">Possui CNH?</label>
                    <select id='cnhExists' style={{ border: missingRequiredFields['cnhExists'] && (cnhExists == '0' || cnhExists == '') && cnhExists !== '' ? '1px solid #e2a3a7' : '1px solid #999999' }} onChange={(e) => { {setCnhExists(e.target.value); e.target.value === 'false' ? cleanCNH() : ''} }} value={cnhExists && cnhExists != 'false' ? 'true' : 'false'}>
                        <option value={true}>Sim</option>
                        <option selected value={false}>Não</option>
                    </select>
                </div>
                {
                    cnhExists === 'true' &&
                    <>
                        <div>
                            <label style={{ color: missingRequiredFields['cnh'] && !cnh ? 'red' : '#464545' }} htmlFor="cnh"> CNH </label>
                            <input style={{ border: missingRequiredFields['cnh'] && !cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnh' value={cnh} onChange={(e) => setCnh(e.target.value)} className='mediumInput' maxLength='20' />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhCategory'] && cnh ? 'red' : '#464545' }} htmlFor="cnhCategory"> Categoria CNH  </label>
                            <input style={{ border: missingRequiredFields['cnhCategory'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnhCategory' value={cnhCategory} onChange={(e) => setCnhCategory(e.target.value)} className='smallInput' maxLength='4' />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhValidity'] && cnh ? 'red' : '#464545' }} htmlFor="cnhValidity"> Validade CNH </label>
                            <input style={{ border: missingRequiredFields['cnhValidity'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhValidity' value={cnhValidity} onChange={(e) => setCnhValidity(e.target.value)} className='smallInput' />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhIssuer'] && cnh ? 'red' : '#464545' }} htmlFor="cnhIssuer"> Orgão Emissor CNH  </label>
                            <input style={{ border: missingRequiredFields['cnhIssuer'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='cnhIssuer' value={cnhIssuer} onChange={(e) => setCnhIssuer(e.target.value)} className='smallInput' />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhUf'] && cnh ? 'red' : '#464545' }} htmlFor="cnhUf"> UF CNH </label>
                            <select style={{ border: missingRequiredFields['cnhUf'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} id="cnhUf" value={cnhUf} onChange={(e) => setCnhUf(e.target.value)}>
                                <option value="">- Selecione -</option>
                                {
                                    brazilStates.map(state => {
                                        return <option value={state.state_id}> {state.state_id} </option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhFirstDate'] && cnh ? 'red' : '#464545' }} htmlFor="cnhFirstDate"> Data 1ª CNH </label>
                            <input style={{ border: missingRequiredFields['cnhFirstDate'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhFirstDate' value={cnhFirstDate} onChange={(e) => setCnhFirstDate(e.target.value)} className='smallInput' />
                        </div>
                        <div>
                            <label style={{ color: missingRequiredFields['cnhDispatchDate'] && cnh ? 'red' : '#464545' }} htmlFor="cnhDispatchDate"> Data Expedição CNH </label>
                            <input style={{ border: missingRequiredFields['cnhDispatchDate'] && cnh ? '1px solid #e2a3a7' : '1px solid #999999' }} type="date" id='cnhDispatchDate' value={cnhDispatchDate} onChange={(e) => setCnhDispatchDate(e.target.value)} className='smallInput' />
                        </div>
                    </>

                }
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['militaryServiceCard'] && (militaryServiceCard == '0' || militaryServiceCard == '') ? 'red' : '#464545' }} htmlFor="militaryServiceCard"> Carteira Reservista </label>
                    <input style={{ border: missingRequiredFields['militaryServiceCard'] && (militaryServiceCard == '0' || militaryServiceCard == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='militaryServiceCard' value={militaryServiceCard} onChange={(e) => setMilitaryServiceCard(e.target.value)} className='mediumInput' maxLength='13' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['militaryServiceCard'] && (militaryServiceCard == '0' || militaryServiceCard == '') ? 'red' : '#464545' }} htmlFor="militaryServiceCard"> Carteira Reservista </label>
                    <input style={{ border: missingRequiredFields['militaryServiceCard'] && (militaryServiceCard == '0' || militaryServiceCard == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='militaryServiceCard' value={militaryServiceCard} onChange={(e) => setMilitaryServiceCard(e.target.value)} maxLength='13' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['militaryServiceCardUf'] && (militaryServiceCardUf == '0' || militaryServiceCardUf == '') ? 'red' : '#464545' }} htmlFor="militaryServiceCardUf"> UF Carteira Reservista </label>
                    <input style={{ border: missingRequiredFields['militaryServiceCardUf'] && (militaryServiceCardUf == '0' || militaryServiceCardUf == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='militaryServiceCardUf' value={militaryServiceCardUf} onChange={(e) => setMilitaryServiceCardUf(e.target.value)} className='smallInput' maxLength='2' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['deficiency'] && (deficiency == '0' || deficiency == '') ? 'red' : '#464545' }} htmlFor="deficiency"> Possui Deficiência *</label>
                    <select style={{ border: missingRequiredFields['deficiency'] && (deficiency == '0' || deficiency == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiency' value={deficiency} onChange={(e) => setDeficiency((e.target.value === 'true'))} >
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                    </select>
                </div>
                {
                    !deficiency ?

                        (
                            <>
                                <div>
                                    <label htmlFor="deficiencyType"> Tipo de Deficiencia</label>
                                    <input style={{ border: '1px solid #999999' }} type="text" id='deficiencyType' value="Não Aplicável" className='smallInput' disabled />
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['deficiencyRehabilitatedInss'] && (deficiencyRehabilitatedInss == '0' || deficiencyRehabilitatedInss == '') ? 'red' : '#464545' }} htmlFor="deficiencyRehabilitatedInss"> Empregado Reabilitado do INSS </label>
                                    <input style={{ border: missingRequiredFields['deficiencyRehabilitatedInss'] && (deficiencyRehabilitatedInss == '0' || deficiencyRehabilitatedInss == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyRehabilitatedInss' value="Não Aplicável" className='smallInput' disabled />
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['deficiencyPcdQuote'] && (deficiencyPcdQuote == '0' || deficiencyPcdQuote == '') ? 'red' : '#464545' }} htmlFor="deficiencyPcdQuote"> Compôe cota de PcD </label>
                                    <input style={{ border: missingRequiredFields['deficiencyPcdQuote'] && (deficiencyPcdQuote == '0' || deficiencyPcdQuote == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyPcdQuote' value="Não Aplicável" className='smallInput' disabled />
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <label style={{ color: missingRequiredFields['deficiencyType'] && (deficiencyType < 0 || deficiencyType == '') ? 'red' : '#464545' }} htmlFor="deficiencyType"> Tipo de Deficiencia * </label>
                                    <select style={{ border: missingRequiredFields['deficiencyType'] && (deficiencyType < 0 || deficiencyType == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyType' value={deficiencyType} onChange={(e) => setDeficiencyType(parseInt(e.target.value))} className='smallInput' >
                                        <option value="-1">- Selecione -</option>
                                        <option value="1">Deficiente Visual</option>
                                        <option value="2">Deficiente Fisica</option>
                                        <option value="3">Deficiente Auditiva</option>
                                        <option value="4">Deficiente Intelectual</option>
                                        <option value="5">Deficiente Mental</option>
                                        <option value="6">Deficiente Multipla</option>
                                    </select>
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['deficiencyRehabilitatedInss'] && (deficiencyRehabilitatedInss < 0 || deficiencyRehabilitatedInss == '') ? 'red' : '#464545' }} htmlFor="deficiencyRehabilitatedInss"> Empregado Reabilitado do INSS * </label>
                                    <select style={{ border: missingRequiredFields['deficiencyRehabilitatedInss'] && (deficiencyRehabilitatedInss < 0 || deficiencyRehabilitatedInss == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyRehabilitatedInss' value={deficiencyRehabilitatedInss} onChange={(e) => setDeficiencyRehabilitatedInss((e.target.value == 'true'))} className='smallInput' >
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </select>
                                </div>
                                <div>
                                    <label style={{ color: missingRequiredFields['deficiencyPcdQuote'] && (deficiencyPcdQuote < 0 || deficiencyPcdQuote == '') ? 'red' : '#464545' }} htmlFor="deficiencyPcdQuote"> Compôe cota de PcD * </label>
                                    <select style={{ border: missingRequiredFields['deficiencyPcdQuote'] && (deficiencyPcdQuote < 0 || deficiencyPcdQuote == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='deficiencyPcdQuote' value={deficiencyPcdQuote} onChange={(e) => setDeficiencyPcdQuote((e.target.value == 'true'))} className='smallInput' >
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </select>
                                </div>
                            </>
                        )
                }
                <div>
                    <label style={{ color: missingRequiredFields['employeeRace'] && !employeeRace || employeeRace < 0 ? 'red' : '#464545' }} htmlFor="employeeRace"> Raça *</label>
                    <select style={{ border: missingRequiredFields['employeeRace'] && !employeeRace || employeeRace < 0 ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='employeeRace' value={employeeRace} onChange={(e) => setEmployeeRace(parseInt(e.target.value))} >
                        <option value="0" selected>Não informado</option>
                        <option value="1">Branca</option>
                        <option value="2">Preta</option>
                        <option value="3">Amarela</option>
                        <option value="4">Parda</option>
                        <option value="5">Indígena</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressCountry'] && (addressCountry < 0 || addressCountry == '') ? 'red' : '#464545' }} htmlFor="addressCountry"> País * </label>
                    <select style={{ border: missingRequiredFields['addressCountry'] && (addressCountry < 0 || addressCountry == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCountry' value={addressCountry} onChange={(e) => setAddressCountry(parseInt(e.target.value))} >
                        <option value="-1">- Selecione -</option>
                        {countries.map(country => {
                            return <option value={country.country_id}>{country.country_name.toUpperCase()}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressState'] && (addressState < 0 || addressState == '') ? 'red' : '#464545' }} htmlFor="addressState"> Estado * </label>
                    <select style={{ border: missingRequiredFields['addressState'] && (addressState < 0 || addressState == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressState' value={addressState} onChange={(e) => setAddressState(e.target.value)} >
                        <option value="-1">- Selecione -</option>
                        {states.map(state => {
                            return <option value={state.state_id}>{state.state_id.toUpperCase()} - {state.state_name.toUpperCase()}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressCities'] && (addressCity < 0 || addressCity == '') ? 'red' : '#464545' }} htmlFor="addressCities"> Cidade * </label>
                    <select style={{ border: missingRequiredFields['addressCities'] && (addressCity < 0 || addressCity == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCities' value={addressCity} onChange={(e) => setAddressCity(parseInt(e.target.value))} >
                        <option value="0">- Selecione -</option>
                        {cities.map(city => {
                            return <option value={city.city_id}>{city.city_name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressDistrict'] && (addressDistrict == '0' || addressDistrict == '') ? 'red' : '#464545' }} htmlFor="addressDistrict"> Bairro * </label>
                    <select style={{ border: missingRequiredFields['addressDistrict'] && (addressDistrict == '0' || addressDistrict == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressDistrict' value={addressDistrict} onChange={(e) => setAddressDistrict(parseInt(e.target.value))} >
                        <option value="0">- Selecione -</option>
                        {districts.map(district => {
                            return <option value={district.district_id}>{district.district_name}</option>
                        })}
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressCep'] && (addressCep == '0' || !addressCep || addressCep.length != 8) ? 'red' : '#464545' }} htmlFor="addressCep"> CEP * </label>
                    <input style={{ border: missingRequiredFields['addressCep'] && (addressCep == '0' || !addressCep || addressCep.length != 8) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressCep' value={maskCep(addressCep)} onChange={(e) => setAddressCep(e.target.value)} className='smallInput' maxlength='9' minLength='9' />
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressType'] && (addressType < 0 || addressType == '') ? 'red' : '#464545' }} htmlFor="addressType"> Tipo de Logradouro * </label>
                    <select style={{ border: missingRequiredFields['addressType'] && (addressType < 0 || addressType == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressType' value={addressType} onChange={(e) => setAddressType(e.target.value)} >
                        <option value="R">Rua</option>
                        <option value="AV">Avenida</option>
                        <option value="VLA">Viela</option>
                        <option value="TV">Travessa</option>
                        <option value="ROD">Rodovia</option>
                        <option value="EST">Estrada</option>
                    </select>
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['addressRoad'] && (addressRoad == '0' || addressRoad == '') ? 'red' : '#464545' }} htmlFor="addressRoad"> Logradouro * </label>
                    <input style={{ border: missingRequiredFields['addressRoad'] && (addressRoad == '0' || addressRoad == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressRoad' value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)} className='bigInput' />
                </div>
                {/* Mobile */}
                <div className='flex flex-col lg:hidden' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['addressRoad'] && (addressRoad == '0' || addressRoad == '') ? 'red' : '#464545' }} htmlFor="addressRoad"> Logradouro * </label>
                    <input style={{ border: missingRequiredFields['addressRoad'] && (addressRoad == '0' || addressRoad == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressRoad' value={addressRoad} onChange={(e) => setAddressRoad(e.target.value)}/>
                </div>
                <div>
                    <label style={{ color: missingRequiredFields['addressNumber'] && (addressNumber === '0' || addressNumber == '') ? 'red' : '#464545' }} htmlFor="addressNumber"> Número * </label>
                    <input style={{ border: missingRequiredFields['addressNumber'] && (addressNumber === '0' || addressNumber == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressNumber' value={addressNumber} onChange={(e) => setAddressNumber(e.target.value)} className='soSmallInputLittle' maxlength='6' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['addressComplement'] && (addressComplement == '0' || addressComplement == '') ? 'red' : '#464545' }} htmlFor="addressComplement"> Complemento </label>
                    <input style={{ border: missingRequiredFields['addressComplement'] && (addressComplement == '0' || addressComplement == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressComplement' value={addressComplement}  maxLength={25} onChange={(e) => setAddressComplement(e.target.value)} className='mediumInput' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['addressComplement'] && (addressComplement == '0' || addressComplement == '') ? 'red' : '#464545' }} htmlFor="addressComplement"> Complemento </label>
                    <input style={{ border: missingRequiredFields['addressComplement'] && (addressComplement == '0' || addressComplement == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id='addressComplement' value={addressComplement}  maxLength={25} onChange={(e) => setAddressComplement(e.target.value)} />
                </div>
                <div>
                    <label htmlFor="phoneDdd"> DDD do Telefone </label>
                    <input type="text" id='phoneDdd' value={phoneDdd} onChange={(e) => setPhoneDdd(e.target.value)} className='soSmallInput' maxLength='3' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label htmlFor="phoneNumber"> Número do Telefone</label>
                    <input type="text" id='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='mediumInput' maxLength='20' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="phoneNumber"> Número do Telefone</label>
                    <input type="text" id='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} maxLength='20' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label htmlFor="employeeEmail"> E-mail Particular</label>
                    <input type="text" id='employeeEmail' value={employeeEmail} onChange={(e) => setEmployeeEmail(e.target.value)} className='bigInput' maxLength='100' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' value={employeeEmailCorporate} onChange={(e) => setEmployeeEmailCorporate(e.target.value)} className='bigInput' maxLength='100' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmail"> E-mail Particular</label>
                    <input type="text" id='employeeEmail' value={employeeEmail} onChange={(e) => setEmployeeEmail(e.target.value)} maxLength='100' />
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label htmlFor="employeeEmailCorporate"> E-mail Corporativo </label>
                    <input type="text" id='employeeEmailCorporate' value={employeeEmailCorporate} onChange={(e) => setEmployeeEmailCorporate(e.target.value)} maxLength='100' />
                </div>
                <div className='hidden lg:flex lg:flex-col lg:h-12 lg:pt-2 2xl:pt-0'>
                    <label htmlFor="dateEmploymentExamination"> Data do exame Admissional </label>
                    <input type="date" id='dateEmploymentExamination' value={dateEmploymentExamination} onChange={(e) => setDateEmploymentExamination(e.target.value)} className='mediumInput' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col pb-5' id='onlyMobile'>
                    <label htmlFor="dateEmploymentExamination"> Data do exame Admissional </label>
                    <input type="date" id='dateEmploymentExamination' value={dateEmploymentExamination} onChange={(e) => setDateEmploymentExamination(e.target.value)}/>
                </div>
            </div>
        )
    }

    function dependentsForm() {
        return (
            <div id='partForm' className='FormRequestContainer overflow-auto xl:h-full max-h-[75vh] h-[65vh] top-0 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400'>
                {
                    selectedForm == 4 ?
                        <></>
                        :
                        <div className="formDescription" >
                            <p className="obsFormRequestAdmission"> Todos os campos com (*) são obrigatórios</p>
                        </div>
                }
                {
                    insideLoading ?
                        (
                            <div className='insideLoadingForm'>
                                <div>
                                    <Loader />
                                </div>
                            </div>
                        ) : <></>
                }
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: missingRequiredFields['motherName'] && (motherName < 0 || motherName == '') ? 'red' : '#464545' }} htmlFor="motherName"> Nome da mãe do funcionário(a) * </label>
                    <input type="text" id='motherName' style={{ border: missingRequiredFields['motherName'] && (motherName < 0 || motherName == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} value={motherName} onChange={(e) => setMotherName(e.target.value)} className='bigInput' />
                </div>                
                <div className='hidden lg:flex lg:flex-col lg:h-7'>
                    <label style={{ color: '#464545' }} htmlFor="fatherName"> Nome do pai do funcionário(a) </label>
                    <input style={{ border: '1px solid #999999' }} type="text" id='fatherName' value={fatherName} onChange={(e) => setFatherName(e.target.value)} className='bigInput' />
                </div>
                {/* Mobile */}
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: missingRequiredFields['motherName'] && (motherName < 0 || motherName == '') ? 'red' : '#464545' }} htmlFor="motherName"> Nome da mãe do funcionário(a) * </label>
                    <input type="text" id='motherName' style={{ border: missingRequiredFields['motherName'] && (motherName < 0 || motherName == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} value={motherName} onChange={(e) => setMotherName(e.target.value)}/>
                </div>
                <div className='flex lg:hidden flex-col' id='onlyMobile'>
                    <label style={{ color: '#464545' }} htmlFor="fatherName"> Nome do pai do funcionário(a) </label>
                    <input style={{ border: '1px solid #999999' }} type="text" id='fatherName' value={fatherName} onChange={(e) => setFatherName(e.target.value)}/>
                </div>
                <div>
                    <div className='flex flex-col items-center justify-center'>
                        {
                            dependents?.map((dependent, index) => {
                                const id = `dependentType${index}`
                                return (
                                    <div className='border-b border-b-[#005f9e36] flex items-center justify-center gap-2'>
                                        <div className='dependentsContainer'>
                                            <div>
                                                <label style={{ color: missingRequiredFields['dependentType' + index] && (dependent['dependentType'] < 0 || dependent['dependentType'] == '') ? 'red' : '#464545' }} htmlFor={`dependentType${index}`}>Tipo *</label>
                                                <select style={{ border: missingRequiredFields['dependentType' + index] && (dependent['dependentType'] < 0 || dependent['dependentType'] == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id={id} value={dependent.dependentType} onChange={(e) => { { dependent.dependentType = parseInt(e.target.value); setDependents([...dependents]) } }}>
                                                    <option value="">- Selecione -</option>
                                                    <option value="1">Filho</option>
                                                    <option value="2">Cônjuge</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label style={{ color: missingRequiredFields['name' + index] && (dependent['name'] < 0 || dependent['name'] == '') ? 'red' : '#464545' }} htmlFor={`dependentName${index}`}>Nome *</label>
                                                <input style={{ border: missingRequiredFields['name' + index] && (dependent['name'] < 0 || dependent['name'] == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id={`dependentName${index}`} value={dependent.name} onChange={(e) => { dependent.name = e.target.value; setDependents([...dependents]) }} className='smallInput' maxLength={40}/>
                                            </div>
                                            <div className='disableFlexGrow'>
                                                <label style={{ color: (missingRequiredFields['cpf' + index] && (dependent['cpf'] < 0 || dependent['cpf'] == '') || invalidDependentCPF.indexOf(index) > -1) ? 'red' : '#464545' }} htmlFor={`dependentType${index}`}>{invalidDependentCPF.indexOf(index) > -1 ? 'CPF Inválido' : 'CPF *'}</label>
                                                <input onBlur={() => validEspecificCPFDenpendent(dependent.cpf, index)} style={{ border: (missingRequiredFields['cpf' + index] && (dependent['cpf'] < 0 || dependent['cpf'] == '') || invalidDependentCPF.indexOf(index) > -1) ? '1px solid #e2a3a7' : '1px solid #999999' }} type="text" id={`dependentCPF${index}`} value={dependent.cpf} onChange={(e) => { dependent.cpf = e.target.value; setDependents([...dependents]) }} className='soSmallInput' maxlength="11" />
                                            </div>
                                            <div>
                                                <label htmlFor={`dependentIRRF${index}`}>Dep. de IRRF *</label>
                                                <select id={`dependentIRRF${index}`} value={dependent.irrfDependent} onChange={(e) => { dependent.irrfDependent = (e.target.value == 'true'); setDependents([...dependents]) }}>
                                                    <option value="true">Sim</option>
                                                    <option value="false">Não</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor={`dependentHealthPlan${index}`}> Plano de Saúde *</label>
                                                <select id={`dependentHealthPlan${index}`} value={dependent.healthPlan} onChange={(e) => { dependent.healthPlan = (e.target.value == 'true'); setDependents([...dependents]) }}>
                                                    <option value="true">Sim</option>
                                                    <option value="false">Não</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor={`dependentHasDeficient${index}`}>Deficiente *</label>
                                                <select id={`dependentHasDeficient${index}`} value={dependent.hasDeficiency} onChange={(e) => { dependent.hasDeficiency = (e.target.value == 'true'); setDependents([...dependents]) }}>
                                                    <option value="true">Sim</option>
                                                    <option value="false">Não</option>
                                                </select>
                                            </div>
                                            <div>
                                                {
                                                    dependent.hasDeficiency == true ?
                                                        (
                                                            <>
                                                                <label style={{ color: missingRequiredFields['deficiency' + index] && (dependent['deficiency'] < 0 || dependent['deficiency'] == '') ? 'red' : '#464545' }} htmlFor={`dependentDeficient${index}`}>Deficiência *</label>
                                                                <select style={{ border: missingRequiredFields['deficiency' + index] && (dependent['deficiency'] < 0 || dependent['deficiency'] == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} id={`dependentDeficient${index}`} value={dependent.deficiency} onChange={(e) => { dependent.deficiency = parseInt(e.target.value); setDependents([...dependents]) }} className='smallInput'>
                                                                    <option value={0}>- Selecione -</option>
                                                                    <option value="1">Deficiente Visual</option>
                                                                    <option value="2">Deficiente Fisica</option>
                                                                    <option value="3">Deficiente Auditiva</option>
                                                                    <option value="4">Deficiente Intelectual</option>
                                                                    <option value="5">Deficiente Mental</option>
                                                                    <option value="6">Deficiente Multipla</option>
                                                                </select>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label htmlFor={`dependentDeficient${index}`}>Deficiência</label>
                                                                <input type="text" id='laborUnion' value='Não aplicável' className="soSmallInput" disabled />
                                                            </>
                                                        )
                                                }
                                            </div>
                                            <div>
                                                <label style={{ color: missingRequiredFields['birthday' + index] && (dependent['birthday'] < 0 || dependent['birthday'] == '' || (thisYear - parseInt(dependent['birthday']?.slice(0, 4)) > 100)) ? 'red' : '#464545' }} htmlFor={`dependentBirthday${index}`}>Data de Nascimento *</label>
                                                <input type='date' className='smallInput' id={`dependentBirthday${index}`} value={dependent.birthday} onChange={(e) => { dependent.birthday = e.target.value; setDependents([...dependents]) }} />
                                            </div>
                                            <div>
                                                <label style={{ color: missingRequiredFields['sexualGender' + index] && (dependent['sexualGender'] < 0 || dependent['sexualGender'] == '') ? 'red' : '#464545' }} htmlFor={`sexualGender${index}`}>Sexo *</label>
                                                <select id={`sexualGender${index}`} value={dependent.sexualGender} onChange={(e) => { dependent.sexualGender = e.target.value; setDependents([...dependents]) }} >
                                                    <option value="">- Selecione -</option>
                                                    <option value="M">Masculino</option>
                                                    <option value="F">Feminino</option>
                                                </select>
                                            </div>
                                            {
                                                dependent.dependentType == 2 ? () => {
                                                    dependent.courtPension = undefined
                                                    return (
                                                        <div>
                                                            <label htmlFor={`dependentCourtPension${index}`}>Pensão Judicial *</label>
                                                            <input type='text' id={`dependentCourtPension${index}`} value='Não aplicável' className="soSmallInput" disabled />
                                                        </div>
                                                    )
                                                } : (
                                                    <div>
                                                        <label htmlFor={`dependentCourtPension${index}`}>Pensão Judicial *</label>
                                                        <select type='date' id={`dependentCourtPension${index}`} value={dependent.courtPension} onChange={(e) => { dependent.courtPension = (e.target.value == 'true'); setDependents([...dependents]) }} >
                                                            <option value="true">Sim</option>
                                                            <option value="false">Não</option>
                                                        </select>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <FiX id="hiddenButtonDependents" cursor='pointer' onClick={() => { removeDependent(dependent) }} size='17' />
                                    </div>
                                )
                            })
                        }
                        <button 
                            type='button'
                            id="hiddenButtonDependents"
                            className='mt-1 mb-3 border items-center border-[#005F9E] p-1 flex gap-1 bg-primaryDefaultLight
                             text-white duration-150 rounded-sm lg:cursor-pointer lg:hover:bg-white lg:hover:text-primaryDefaultLight' 
                            onClick={() => setDependents([...dependents, { dependentType: -1, name: '', cpf: '', irrfDependent: false, healthPlan: false, hasDeficiency: false, deficiency: -1, courtPension: false, birthday: '', sexualGender: '' }])}
                        >
                            <FiPlus/> Dependente
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    function handleVoterRegistration(){

        const elementValue = document.getElementById('voterRegistration')?.value

        if(elementValue?.toString()?.length > 0){
             return true
        }else {
            return false
        }
    }

    function removeDependent(dependent) {
        const dependentsCopy = [...dependents]
        const index = dependentsCopy.indexOf(dependent)
        if (index != -1) {
            dependentsCopy.splice(index, 1)
            setDependents(dependentsCopy)
        }
    }

    function validateFormModal() {

        const inputs = document.querySelectorAll('input')
        const selects = document.querySelectorAll('select')
        const arrayInputs = Array.prototype.slice.call(inputs);
        const arraySelects = Array.prototype.slice.call(selects);
        let existsMissingFields = false

        const requiredFields = [

            'employeeScheduleType',
            'employeeScheduleMonday',
            'employeeScheduleTuesday',
            'employeeScheduleWednesday',
            'employeeScheduleThursday',
            'employeeScheduleFriday',
            'employeeScheduleSaturday',
            'employeeScheduleSunday'

        ]

        arrayInputs.map((input) => {

            if ((input.value == '' || input.value <= 0) && (requiredFields.indexOf(input.id) > -1)) {
                missingRequiredFieldsModal[input.id] = true
                existsMissingFields = true
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)

            } else {
                missingRequiredFieldsModal[input.id] = false
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)
            }
        })

        arraySelects.map((select) => {
            if ((select.value == 0 || select.value == '0' || !select.value) && (requiredFields.indexOf(select.id) > -1)) {
                missingRequiredFieldsModal[select.id] = true
                existsMissingFields = true
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)

            } else {
                missingRequiredFieldsModal[select.id] = false
                setMissingRequiredFieldsModal(missingRequiredFieldsModal)
            }
        })

        setMissingRequiredFieldsModal(missingRequiredFieldsModal)

        if (existsMissingFields) {
            setInsideLoading(true)
            setTimeout(() => {
                setInsideLoading(false)
            }, [300])
            return 1
        } else {
            return 0
        }
    }

    function validateForm(formToValidate) {

        let existsMissingFields = false
        let dateDependentsError = false
        
        const contractRequired =
            [
                { id: 'employeeName', value: employeeName, depends: '' }, { id: 'admissionDate', value: admissionDate, depends: '' },
                { id: 'typeApuration', value: typeApuration, depends: '' }, { id: 'employeeLaborUnion', value: employeeLaborUnion, depends: '' },
                { id: 'laborUnion', value: laborUnion, depends: employeeLaborUnion }, { id: 'employeePosition', value: employeePosition, depends: '' },
                { id: 'typeSalary', value: typeSalary, depends: '' },
                { id: 'typeBusinessLink', value: typeBusinessLink, depends: '' }, { id: 'employeeLocal', value: employeeLocal, depends: '' },
                { id: 'employeeVT', value: employeeVT, depends: '' }, { id: 'employeeBank', value: employeeBank, depends: paymentMethod },
                { id: 'employeeBankAccountType', value: employeeBankAccountType, depends: paymentMethod }, { id: 'employeeValueVT', value: employeeValueVT, depends: employeeVT },
                { id: 'employeeBankAccount', value: employeeBankAccount, depends: paymentMethod }, { id: 'employeeBankAccountDigit', value: employeeBankAccountDigit, depends: paymentMethod },
                { id: 'employeeBankAgencies', value: employeBankAgencie, depends: paymentMethod }, { id: 'advanceSalaryPorcentage', value: advanceSalaryPorcentage, depends: '' },
                { id: 'employeeExperience', value: employeeExperience, depends: '' }, { id: 'lifeInsurance', value: lifeInsurance, depends: '' }, { id: 'insalubrity', value: insalubrity, depends: '' },
                { id: 'periculosity', value: periculosity, depends: '' }, { id: 'paymentPeriod', value: paymentPeriod }, { id: 'paymentMethod', value: paymentMethod, depends: '' }
            ]

        if (typeSalary != 4 || typeSalary != '4'){
            contractRequired.push({ id: 'employeeSalary', value: employeeSalary, depends: '' })
        }

        if (companyConfig?.useNatureExpenses == 'S'){
            contractRequired.push({ id: 'natureExpense', value: natureExpense, depends: '' })
        }

        if (typeBusinessLink == '90'){
            contractRequired.push({ id: 'traineeInsurancePolicy', value: traineeInsurancePolicy, depends: '' })
            contractRequired.push({ id: 'traineeEndForecast', value: traineeEndForecast, depends: '' })
            contractRequired.push({ id: 'traineeEducationalInstitution', value: traineeEducationalInstitution, depends: '' })
            contractRequired.push({ id: 'traineeCodSupervisor', value: traineeCodSupervisor, depends: '' })
            contractRequired.push({ id: 'traineeOrigin', value: traineeOrigin, depends: '' })
            contractRequired.push({ id: 'traineeEducationLevel', value: traineeEducationLevel, depends: '' })
        }

        if (typeBusinessLink == '55'){
            contractRequired.push({ id: 'apprenticeInstitutionName', value: apprenticeInstitutionName, depends: '' })
            contractRequired.push({ id: 'apprenticeInstitutionCPNJ', value: apprenticeInstitutionCNPJ, depends: '' })
            contractRequired.push({ id: 'apprenticeDateFinish', value: apprenticeDateFinish, depends: '' })
        }

        const employeeRequired =
        [
            { id: 'employeeSexualGender', value: employeeSexualGender, depends: '' },
            { id: 'civilStatus', value: civilStatus, depends: '' },
            { id: 'levelOfEducation', value: levelOfEducation, depends: '' },
            { id: 'birthday', value: birthday, depends: '' },
            { id: 'nationality', value: nationality, depends: '' },
            { id: 'employeeUF', value: employeeUf, depends: '' },
            { id: 'employeeCity', value: employeeUf, depends: '' },
            { id: 'workCard', value: workCard, depends: '' },
            { id: 'workCardSerie', value: workCardSerie, depends: '' },
            { id: 'cpf', value: cpf, depends: '' }, /*{ id: 'pis', value: pis, depends: '' }, { id: 'emissionPis', value: emissionPis, depends: '' },*/
            { id: 'deficiency', value: deficiency, depends: '' },
            { id: 'deficiencyType', value: deficiencyType, depends: deficiency },
            { id: 'deficiencyRehabilitatedInss', value: deficiencyRehabilitatedInss, depends: deficiency },
            { id: 'deficiencyPcdQuote', value: deficiencyPcdQuote, depends: deficiency },
            { id: 'addressState', value: addressState, depends: '' },
            { id: 'addressCities', value: addressCity, depends: '' },
            { id: 'addressDistrict', value: addressDistrict, depends: '' },
            { id: 'addressCep', value: addressCep, depends: '' },
            { id: 'addressRoad', value: addressRoad, depends: '' },
            { id: 'addressNumber', value: addressNumber, depends: '' },
            { id: 'addressCountry', value: addressCountry, depends: '' },
            { id: 'addressType', value: addressCountry, depends: '' },
            { id: 'employeeRace', value: employeeRace, depends: '' }
        ]

        if (nationality != 10){
            employeeRequired.push({ id: 'foreignResidenceTime', value: foreignResidenceTime, depends: '' })
            employeeRequired.push({ id: 'foreignCondition', value: foreignCondition, depends: '' })
            employeeRequired.push({ id: 'foreignArrivalDate', value: foreignArrivalDate, depends: '' })
            employeeRequired.push({ id: 'foreignRegistry', value: foreignRegistry, depends: '' })
            employeeRequired.push({ id: 'foreignDateExpRegistry', value: foreignDateExpRegistry, depends: '' })
            employeeRequired.push({ id: 'foreignVisaValidity', value: foreignVisaValidity, depends: '' })
            employeeRequired.push({ id: 'foreignDateValidityCTPS', value: foreignDateValidityCTPS, depends: '' })
            employeeRequired.push({ id: 'foreignCasWithBrazilian', value: foreignCasWithBrazilian, depends: '' })
            employeeRequired.push({ id: 'foreignBrazilianChildren', value: foreignBrazilianChildren, depends: '' })
        }

        if (foreignResidenceTime == 2){
            employeeRequired.push({ id: 'foreignFinishResidence', value: foreignFinishResidence, depends: '' })
        }
        
        if (cnh || cnhExists) {
            employeeRequired.push({ id: 'cnh', value: cnh, depends: '' })
            employeeRequired.push({ id: 'cnhCategory', value: cnhCategory, depends: '' })
            employeeRequired.push({ id: 'cnhDispatchDate', value: cnhDispatchDate, depends: '' })
            employeeRequired.push({ id: 'cnhFirstDate', value: cnhFirstDate, depends: '' })
            employeeRequired.push({ id: 'cnhIssuer', value: cnhIssuer, depends: '' })
            employeeRequired.push({ id: 'cnhUf', value: cnhUf, depends: '' })
            employeeRequired.push({ id: 'cnhValidity', value: cnhValidity, depends: '' })
        }
        if(handleVoterRegistration()){
            employeeRequired.push({ id: 'voterRegistrationZone', value: voterRegistrationZone, depends: '' }),
            employeeRequired.push({ id: 'voterRegistrationSection', value: voterRegistrationSection, depends: '' })
        }
        
        const dependentsRequired = [
            { id: 'motherName', value: motherName, depends: '' }
        ]

        //Verifica os campos do formulário dados de contrato
        contractRequired.map((field) => {
            if ((formToValidate > 1 || !formToValidate) &&
                (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                (field.depends === true || field.depends === '' || field.depends === 'R')
            ) {
                setErrorFields({ name: 'contractForm' })
                missingRequiredFields[field.id] = true
                existsMissingFields = true
                setMissingRequiredFields(missingRequiredFields)
                setSelectedForm(1)
            } else {
                missingRequiredFields[field.id] = false
                setMissingRequiredFields(missingRequiredFields)
            }
        })

        //Verifica os campos do formulário dados do empregado
        if (!existsMissingFields) {
            employeeRequired.map((field) => {
                if (
                    (formToValidate > 2 || !formToValidate) &&
                    (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                    (field.depends === true || field.depends === '' || field.depends === 'R')
                ) { 
                    setErrorFields({ name: 'employeeForm' })
                    missingRequiredFields[field.id] = true
                    existsMissingFields = true
                    setMissingRequiredFields(missingRequiredFields)
                    setSelectedForm(2)
                } else {
                    if(field.id == 'addressCep' && field.value !== null && field.value.length < 8 && field.value !== ''){      
                        missingRequiredFields[field.id] = true
                        setMissingRequiredFields(missingRequiredFields)
                    }else{        
                        // console.log(missingRequiredFields)
                        missingRequiredFields[field.id] = false
                        setMissingRequiredFields(missingRequiredFields)
                    }
                }
            })
        }

        //Verifica os campos do formulário dados de dependentes
        if (!existsMissingFields && (formToValidate > 3 || !formToValidate)) {
            dependentsRequired.map((field) => {

                if (!formToValidate &&
                    (field.value < 0 || (field.value === '0' && field.id !== 'employeeBankAccountDigit') || field.value === '') &&
                    (field.depends === true || field.depends === '' || field.depends === 'R')
                ) {
                    setErrorFields({ name: 'dependentsForm' })
                    missingRequiredFields[field.id] = true
                    existsMissingFields = true
                    setMissingRequiredFields(missingRequiredFields)
                    setSelectedForm(3)
                } else {
                    missingRequiredFields[field.id] = false
                    setMissingRequiredFields(missingRequiredFields)
                }
            })

            //Verifica os dados dos dependentes, se estão todos preenchidos
            dependents.map((dependent, index) => {

                const keyTocheck = Object.keys(dependent)

                if (!existsMissingFields) {
                    keyTocheck.map((field) => {
                        if (field === 'cpf' && !validateDependentsCPF(dependents[index][field])) {
                            const positionThisCpf = invalidDependentCPF.indexOf(index)
                            setErrorFields({ name: 'dependentsForm' })
                            missingRequiredFields[field + index] = true
                            existsMissingFields = true
                            setSelectedForm(3)
                            setMissingRequiredFields(missingRequiredFields)
                            if (positionThisCpf > -1) {
                                return
                            }
                            invalidDependentCPF.push(index)
                            setInvalidDependentCPF([...invalidDependentCPF])
                        } else if (field === 'cpf' && validateDependentsCPF(dependents[index][field])) {
                            const positionThisCpf = invalidDependentCPF.indexOf(index)
                            if (positionThisCpf > -1) {
                                invalidDependentCPF.splice(positionThisCpf, 1)
                                setInvalidDependentCPF([...invalidDependentCPF])
                            }
                        }
                        if (field === 'birthday') {
                            const birthdayYear = parseInt(dependents[index][field]?.slice(0, 4))
                            if (((thisYear - birthdayYear) > 100) || dependents[index][field] == '') {
                                dateDependentsError = true
                                missingRequiredFields[field + index] = true
                                setErrorFields({ name: 'dependentsForm' })
                                setMissingRequiredFields(missingRequiredFields)
                                return alert(`Data de nascimento do dependente ${dependents[index]['name']} na linha ${index + 1} invalida`)
                            }
                        }

                        if (!formToValidate && (formToValidate === 3 || !formToValidate) && ((dependents[index][field] < 0 || dependents[index][field] === '0' || dependents[index][field] === '') && field !== 'deficiency')
                            ||
                            (dependents[index]['hasDeficiency'] === true && (dependents[index]['deficiency'] < 0 || dependents[index]['deficiency'] === '0' || dependents[index]['deficiency'] === ''))
                        ) {
                            setErrorFields({ name: 'dependentsForm' })
                            missingRequiredFields[field + index] = true
                            existsMissingFields = true
                            setMissingRequiredFields(missingRequiredFields)
                            setSelectedForm(3)
                        } else {
                            missingRequiredFields[field + index] = false
                            setMissingRequiredFields(missingRequiredFields)
                        }
                    })
                }
            })

        }

        if (existsMissingFields || validateFormModal() || dateDependentsError) {

            return true
        }
    }

    function validEspecificCPFDenpendent(cpf, index) {

        if (!validateDependentsCPF(cpf)) {
            const positionThisCpf = invalidDependentCPF.indexOf(index)
            setErrorFields({ name: 'dependentsForm' })
            missingRequiredFields['cpf' + index] = true
            setSelectedForm(3)
            setMissingRequiredFields(missingRequiredFields)
            if (positionThisCpf > -1) {
                return
            }
            invalidDependentCPF.push(index)
            setInvalidDependentCPF([...invalidDependentCPF])
        } else if (validateDependentsCPF(cpf)) {
            const positionThisCpf = invalidDependentCPF.indexOf(index)
            if (positionThisCpf > -1) {
                invalidDependentCPF.splice(positionThisCpf, 1)
                setInvalidDependentCPF([...invalidDependentCPF])
            }
        }
    }

    function handleCPF(e) {

        if (e) {
            setCpf(e.target.value)
            const thisCPFStart = e.target.value.slice(0, 9)
            const thisCPFEnd = e.target.value.slice(9, e.target.value.length)
            setWorkCard(thisCPFStart.replaceAll('.', ''))
            setWorkCardSerie(thisCPFEnd.replaceAll('-', ''))

        } else {
            const thisCPFStart = cpf.slice(0, 9)
            const thisCPFEnd = cpf.slice(9, cpf.length)
            setWorkCard(thisCPFStart.replaceAll('.', ''))
            setWorkCardSerie(thisCPFEnd.replaceAll('-', ''))
        }

    }

    function handleDates(dateToHandle) {

        switch (dateToHandle === 'all') {
            case birthday > admissionDate:
                setDateError({ date: dateToHandle, message: 'Data de nascimento maior do que a data de admissão' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            case ((emissionPis !== '' && (emissionPis < birthday)) || (EmissionRG !== '' && (EmissionRG < birthday))):
                setDateError({ date: dateToHandle, message: 'Data de emissão do PIS ou RG menor do que a data de nascimento, verifique' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            case ((cnhFirstDate !== '' && (cnhFirstDate < birthday)) || (cnhDispatchDate !== '' && (cnhDispatchDate < birthday))):
                setDateError({ date: dateToHandle, message: 'Data de emissão da CNH ou da expedição da CNH menor do que a data de nascimento, verifique' })
                setErrorFields({ name: 'date' })
                setShow(true)
                return true
            default:
                return false;
        }

    }

    async function checkFillTabs(formToShow) {
        if (handleDates('all') === true) {
            return
        }
        setInsideLoading(true)
        if (invalidCPF) {
            setErrorFields({ name: 'invalidCPF' })
            setTimeout(() => {
                setInsideLoading(false)
                return setShow(true)
            }, [300])
            return
        }
        if (validateForm(formToShow)) {
            setTimeout(() => {
                setInsideLoading(false)
                setShow(true)
            }, [300])
            return
        }

        if (typeSalary === 1 && employeeSalary <= 50){
            setInsideLoading(false)
            return alert('Mensalista precisa ter o salário base maior que R$ 50,00')
        } else if (typeSalary === 2 && employeeSalary >= 50){
            setInsideLoading(false)
            return alert('Horista precisa ter o salário base menor que R$ 50,00')
        }

        if (formToShow == 3 && pis && !validarPIS(pis)){
            setInsideLoading(false)
            return alert('O campo "PIS" é inválido. Verifique os dados')
        }
        if (formToShow == 3 && employeeRace == '0'){
            setInsideLoading(false)
            return alert('O campo "Raça" é obrigatório. Verifique os dados')
        }

        setInsideLoading(false)
        setSelectedForm(formToShow)

    }

    async function handleSubmit(e) {
        scrollTopDiv()

        e.preventDefault()
        if (handleDates('all') === true) {
            return
        }

        if (invalidCPF) {
            setErrorFields({ name: 'invalidCPF' })
            setTimeout(() => {
                setInsideLoading(false)
                setShow(true)
            }, [300])
            return
        }

        if (employeeSalary){
            if (employeeSalary > 16000){
                return alert('O campo "Salário Base" precisa ser menor que R$ 16.000,00')
            }
        }

        if(addressCep.length < 8){
            setInsideLoading(false)
            return alert('Voce precisa informar um CEP com 8 digitos')
        }

        setInsideLoading(true)
        if (validateForm()) {
            setTimeout(() => {
                setInsideLoading(false)
                setShow(true)
            }, [300])
            return
        }
        // setInsideLoading(false)

        try {
            setInsideLoading(true)

            await api.post(`api/v1/employees/company/${company}/branch/${branch}`, {
                employeeName, admissionDate, 
                // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                isIntermittent: !isIntermittent ? undefined : true,
                typeBusinessLink: isIntermittent && typeBusinessLink == 11 ? 10 : typeBusinessLink, 
                typeApuration, employeeLaborUnion, employeePosition,
                laborUnion: !laborUnion ? undefined : laborUnion, 
                typeSalary, 
                employeeSalary: typeSalary == 4 ? 0 : employeeSalary,
                employeeLocal,
                employeeScheduleType, 
                employeeScheduleMonday: employeeScheduleMonday && employeeScheduleMonday != '0' && employeeScheduleMonday != 0 ? employeeScheduleMonday : 'n/d', 
                employeeScheduleTuesday: employeeScheduleTuesday && employeeScheduleTuesday != '0' && employeeScheduleTuesday != 0 ? employeeScheduleTuesday : 'n/d', 
                employeeScheduleWednesday: employeeScheduleWednesday && employeeScheduleWednesday != '0' && employeeScheduleWednesday != 0 ? employeeScheduleWednesday : 'n/d', 
                employeeScheduleThursday: employeeScheduleThursday && employeeScheduleThursday != '0' && employeeScheduleThursday != 0 ? employeeScheduleThursday : 'n/d', 
                employeeScheduleFriday: employeeScheduleFriday && employeeScheduleFriday != '0' && employeeScheduleFriday != 0 ? employeeScheduleFriday : 'n/d',
                employeeScheduleSaturday: employeeScheduleSaturday && employeeScheduleSaturday != '0' && employeeScheduleSaturday != 0 ? employeeScheduleSaturday : 'n/d', 
                employeeScheduleSunday: employeeScheduleSunday && employeeScheduleSunday != '0' && employeeScheduleSunday != 0 ? employeeScheduleSunday : 'n/d', 
                employeeCCU: !employeeCCU ? undefined : employeeCCU,
                employeeVT, advanceSalary,
                employeeValueVT: !employeeValueVT ? undefined : employeeValueVT,
                advanceSalaryPorcentage: !advanceSalaryPorcentage ? undefined : advanceSalaryPorcentage,
                employeeExperience, lifeInsurance, insalubrity, periculosity, paymentPeriod, paymentMethod, employeeSexualGender,
                employeeBank: !employeeBank ? undefined : employeeBank,
                employeBankAgencie: !employeBankAgencie ? undefined : employeBankAgencie,
                employeeBankAccountType: !employeeBankAccountType ? undefined : employeeBankAccountType,
                employeeBankAccount: !employeeBankAccount ? undefined : employeeBankAccount,
                employeeBankAccountDigit: !employeeBankAccountDigit ? undefined : employeeBankAccountDigit,
                civilStatus, levelOfEducation, birthday, nationality, employeeUf, employeeCity,
                workCard, workCardSerie, 
                workCardUf: !workCardUf || workCardUf == -1 ? undefined : workCardUf,
                workCardEmission: !workCardEmission ? undefined : workCardEmission,
                cpf: cpf?.length > 11 ? cpf?.replaceAll('.', '')?.replace('-', '') : cpf,
                pis: !pis ? undefined : pis?.replace(/[^\d]+/g, ''),
                emissionPis: !emissionPis ? undefined : emissionPis,
                rg: !rg ? undefined : rg,
                sendingAgencyRG: !sendingAgencyRG ? undefined : sendingAgencyRG,
                EmissionRG: !EmissionRG ? undefined : EmissionRG,
                rgUf: !rgUf ? undefined : rgUf,
                voterRegistration: !voterRegistration ? undefined : voterRegistration,
                voterRegistrationZone: !voterRegistration ? undefined : voterRegistrationZone,
                voterRegistrationSection: !voterRegistration ? undefined : voterRegistrationSection,
                cnh: !cnh ? undefined : cnh,
                cnhIssuer: !cnhIssuer ? undefined : cnhIssuer,
                cnhCategory: !cnhCategory ? undefined : cnhCategory,
                cnhValidity: !cnhValidity ? undefined : cnhValidity,
                cnhUf: !cnhUf ? undefined : cnhUf,
                cnhFirstDate: !cnhFirstDate ? undefined : cnhFirstDate,
                cnhDispatchDate: !cnhDispatchDate ? undefined : cnhDispatchDate,
                militaryServiceCard: !militaryServiceCard ? undefined : militaryServiceCard,
                militaryServiceCardUf: !militaryServiceCardUf ? undefined : militaryServiceCardUf,
                deficiencyType: !deficiencyType == 0 ? undefined : deficiencyType,
                deficiencyRehabilitatedInss: !deficiencyRehabilitatedInss ? undefined : deficiencyRehabilitatedInss,
                deficiencyPcdQuote: !deficiencyPcdQuote ? undefined : deficiencyPcdQuote,
                deficiency, employeeRace, addressCountry, 
                addressCep: addressCep ? addressCep?.replace('-', '') : undefined, 
                addressState,
                addressCity, addressDistrict, addressRoad, addressNumber, addressType,
                addressComplement: !addressComplement ? undefined : addressComplement,
                phoneDdd: !phoneDdd ? undefined : phoneDdd,
                phoneNumber: !phoneNumber ? undefined : phoneNumber,
                employeeEmail: !employeeEmail ? undefined : employeeEmail,
                employeeEmailCorporate: !employeeEmailCorporate ? undefined : employeeEmailCorporate,
                dateEmploymentExamination: !dateEmploymentExamination ? undefined : dateEmploymentExamination,
                fatherName: !fatherName ? undefined : fatherName,
                firstJob: firstJob,
                employeeWorkplace: !employeeWorkplace ? undefined : employeeWorkplace,
                motherName, 
                dependents: dependents.map(field => {
                    delete field.employeeAdmission_id
                    delete field.dependentCpf
                    delete field.dependentIRRF
                    delete field.dependentBirthday
                    delete field.dependentName
                    return field
                }),
                observation: observation ? observation : undefined,
                isSave: save_id ? true : false,
                save_id: save_id ? save_id : '',
                traineeInsurancePolicy: traineeInsurancePolicy ? traineeInsurancePolicy : undefined,
                traineeEndForecast: traineeEndForecast ? traineeEndForecast : undefined,
                traineeEducationalInstitution: traineeEducationalInstitution ? traineeEducationalInstitution : undefined,
                traineeCodSupervisor: traineeCodSupervisor ? traineeCodSupervisor : undefined,
                traineeOrigin: traineeOrigin ? traineeOrigin : undefined,
                traineeEducationLevel: traineeEducationLevel ? traineeEducationLevel : undefined,
                foreignResidenceTime: foreignResidenceTime ? foreignResidenceTime : undefined,
                foreignCondition: foreignCondition ? foreignCondition : undefined,
                foreignFinishResidence: foreignFinishResidence ? foreignFinishResidence : undefined,
                foreignArrivalDate: foreignArrivalDate ? foreignArrivalDate : undefined,
                foreignRegistry: foreignRegistry ? foreignRegistry : undefined,
                foreignDateExpRegistry: foreignDateExpRegistry ? foreignDateExpRegistry : undefined,
                foreignVisaValidity: foreignVisaValidity ? foreignVisaValidity : undefined,
                foreignDateValidityCTPS: foreignDateValidityCTPS ? foreignDateValidityCTPS : undefined,
                foreignCasWithBrazilian: foreignCasWithBrazilian ? foreignCasWithBrazilian : undefined,
                foreignBrazilianChildren: foreignBrazilianChildren ? foreignBrazilianChildren : undefined,
                advanceMonthAdmission: advanceMonthAdmission ? advanceMonthAdmission : undefined,
                natureExpenses: natureExpense ? natureExpense : undefined,
                insalubrityPercentage: insalubrityPercentage ? insalubrityPercentage : undefined,
                periculosityPercentage: periculosityPercentage ? periculosityPercentage : undefined,
                nickname: nickname ? nickname : undefined,
                digitAgency: digitAgency ? digitAgency : undefined,
                apprenticeInstitutionName: !apprenticeInstitutionName ? undefined : apprenticeInstitutionName,
                apprenticeInstitutionCNPJ: apprenticeInstitutionCNPJ ? apprenticeInstitutionCNPJ?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                apprenticeDateFinish: !apprenticeDateFinish ? undefined : apprenticeDateFinish,
                gratificationFunction: gratificationFunction ?? undefined
            }, {
                headers: {
                    authorization: user?.token
                }
            })
            setInsideLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Cadastro realizado com sucesso')
            return navigate('/employeeConsult')

        } catch (error) {
            console.log(error)
            setInsideLoading(false)
            if (error?.response?.data?.message !== undefined) {
                return alert(error?.response?.data?.message)
            }
            setShow(true)            
            return setErrorSubmit(true)
        }
    }

    function validateCPF(cpf, type) {

        let validCPF = false
        setErrorFields({ name: 'invalidCPF' })

        if (cpf?.length > 0) {

            const strCPF = cpf.replace(/[^a-zA-Z0-9s]/g, '')

            if (strCPF == "00000000000") {
                setInvalidCPF(true)
                return validCPF = false
            }

            let sum = 0
            let rest = 0
            for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(9, 10))) {
                setInvalidCPF(true)
                return validCPF = false
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(10, 11))) {
                setInvalidCPF(true)
                return validCPF = false
            }
            validCPF = true
            setErrorFields({})
            setInvalidCPF(!validCPF)
            return validCPF
        }

        setInvalidCPF(!validCPF)
        return validCPF

    }

    function validateDependentsCPF(cpfNum) {

        let validCPF = false
        setErrorFields({ name: 'invalidCPF' })

        if (cpfNum?.length > 0) {

            const strCPF = cpfNum.replace(/[^a-zA-Z0-9s]/g, '')

            if (strCPF == "00000000000") {
                return validCPF = false
            }

            let sum = 0
            let rest = 0
            for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(9, 10))) {
                return validCPF = false
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
            rest = (sum * 10) % 11

            if ((rest == 10) || (rest == 11)) rest = 0
            if (rest != parseInt(strCPF.substring(10, 11))) {
                return validCPF = false
            }
            if (cpfNum === cpf.replace(/[^a-zA-Z0-9s]/g, '')) {
                validCPF = false
                return validCPF
            }
            validCPF = true
            setErrorFields({})
            return validCPF
        }
        return validCPF
    }

    function validateBirthday(date) {
        if (((thisYear - date?.slice(0, 4)) < 14) || (thisYear - date?.slice(0, 4)) > 120) {
            errorFields['birthday'] = true
            setErrorFields(errorFields)
            setInvalidBirthday(true)
            return
        }
        errorFields['birthday'] = false
        setErrorFields(errorFields)
        setInvalidBirthday(false)
        return
    }

    function cleanCNH(){

       setCnh('')
       setCnhExists('')
       setCnhIssuer('')
       setCnhCategory('')
       setCnhValidity('')
       setCnhUf('')
       setCnhFirstDate('')
       setCnhDispatchDate('')

    }

    async function generatePdf(e){
        e.preventDefault()
        
        try {            

            setLoading(true)

            const htmlData = document.getElementById('exportEmployee').innerHTML

            const pdfWindow = window.open('Folpag Connect', 'Folpag Connect', 'width=1920, height= 1080')
            pdfWindow.document.write('<html lang="pt-BR">')
            pdfWindow.document.write('<head>')
            pdfWindow.document.write(`
                <meta charset="utf-8" />
                <title>
                    Colaborador: Nova Admissão
                </title>
                <meta name="description" content="Relatório" />
            `)
            pdfWindow.document.write('</head>')
            pdfWindow.document.write('<style>')
            pdfWindow.document.write(reportStyle)
            pdfWindow.document.write('</style>')
            pdfWindow.document.write('<body>')
            pdfWindow.document.write(htmlData)
            pdfWindow.document.write('</body>')
            pdfWindow.document.write('<script>')
            pdfWindow.document.write(`
                document.querySelector('select[id="typeBusinessLink"]>option[value="${typeBusinessLink}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="typeApuration"]>option[value="${typeApuration}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeLaborUnion"]>option[value="${employeeLaborUnion}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="typeSalary"]>option[value="${typeSalary}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeVT"]>option[value="${employeeVT}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="advanceSalary"]>option[value="${advanceSalary}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeExperience"]>option[value="${employeeExperience}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="lifeInsurance"]>option[value="${lifeInsurance}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="insalubrity"]>option[value="${insalubrity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="periculosity"]>option[value="${periculosity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="paymentPeriod"]>option[value="${paymentPeriod}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="paymentMethod"]>option[value="${paymentMethod}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBank"]>option[value="${employeeBank}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAgencies"]>option[value="${employeBankAgencie}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccountType"]>option[value="${employeeBankAccountType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccount"]>option[value="${employeeBankAccount}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeBankAccountDigit"]>option[value="${employeeBankAccountDigit}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="traineeOrigin"]>option[value="${traineeOrigin}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="traineeEducationLevel"]>option[value="${traineeEducationLevel}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeScheduleType"]>option[value="${employeeScheduleType}"]')?.setAttribute('selected', '')
                
                document.querySelector('select[id="employeeSexualGender"]>option[value="${employeeSexualGender}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="civilStatus"]>option[value="${civilStatus}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="levelOfEducation"]>option[value="${levelOfEducation}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="nationality"]>option[value="${nationality}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeUF"]>option[value="${employeeUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeCity"]>option[value="${employeeCity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="firstJob"]>option[value="${firstJob}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="selectedWorkCardType"]>option[value="${wordCardType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="workCardUF"]>option[value="${workCardUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="RGuf"]>option[value="${rgUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="cnhExists"]>option[value="${cnhExists}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="cnhUf"]>option[value="${cnhUf}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiency"]>option[value="${deficiency}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyType"]>option[value="${deficiencyType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyRehabilitatedInss"]>option[value="${deficiencyRehabilitatedInss}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="deficiencyPcdQuote"]>option[value="${deficiencyPcdQuote}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="employeeRace"]>option[value="${employeeRace}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressCountry"]>option[value="${addressCountry}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressState"]>option[value="${addressState}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressCities"]>option[value="${addressCity}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressDistrict"]>option[value="${addressDistrict}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="addressType"]>option[value="${addressType}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignResidenceTime"]>option[value="${foreignResidenceTime}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignCondition"]>option[value="${foreignCondition}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignCasWithBrazilian"]>option[value="${foreignCasWithBrazilian}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="foreignBrazilianChildren"]>option[value="${foreignBrazilianChildren}"]')?.setAttribute('selected', '')
                document.querySelector('select[id="natureExpense"]>option[value="${natureExpense}"]')?.setAttribute('selected', '')
                
                ${dependents.map((dependent, index) => {
                    return `
                        document.querySelector('select[id="${'dependentType' + index}"]>option[value="${dependent.dependentType}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentIRRF${index}"]>option[value="${dependent.irrfDependent}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentHealthPlan${index}"]>option[value="${dependent.healthPlan}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentHasDeficient${index}"]>option[value="${dependent.hasDeficiency}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentDeficient${index}"]>option[value="${dependent.deficiency}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="${'sexualGender' + index}"]>option[value="${dependent.sexualGender}"]')?.setAttribute('selected', '')
                        document.querySelector('select[id="dependentCourtPension${index}"]>option[value="${dependent.courtPension}"]')?.setAttribute('selected', '')
                    `
                }).join('\n')}
            `)
            pdfWindow.document.write('</script>')
            pdfWindow.document.write('</html>')
            pdfWindow.document.close()
            pdfWindow.print()
            pdfWindow.close()

            setLoading(false)

        } catch (error) {
            setLoading(false)
            return alert('Erro ao realizar geração de PDF')
        }

    }

    async function handleSave(){
        if (handleDates('all') === true) {
            setButtonSave(false)
            return
        }
        if (pis && !validarPIS(pis)){
            return alert('O campo "PIS" é inválido. Verifique os dados')
        }
        if (employeeSalary){
            if (employeeSalary > 16000){
                return alert('O campo "Salário Base" precisa ser menor que R$ 16.000,00')
            }
        }
        setInsideLoading(true)
        try {
            await api.post(`api/v1/employeesSave/company/${company}/branch/${branch}${save_id ? '/save_id/'+save_id : ''}`,{
                employeeName: employeeName ? employeeName : undefined, 
                admissionDate: admissionDate ? admissionDate : undefined, 
                // Verifica se o coloborador é intermitente e corrige para o código certo, pois o código CLT é o mesmo que intermitente.
                isIntermittent: !isIntermittent ? undefined : true,
                typeBusinessLink: isIntermittent && typeBusinessLink == 11 ? 10 : typeBusinessLink, 
                typeApuration: typeApuration ? typeApuration : undefined, 
                employeeLaborUnion: employeeLaborUnion ? employeeLaborUnion : undefined, 
                employeePosition: employeePosition ? employeePosition : undefined,
                laborUnion: !laborUnion ? undefined : laborUnion, 
                typeSalary: typeSalary ? typeSalary : undefined, 
                employeeSalary: typeSalary == 4 ? 0 : employeeSalary,
                employeeLocal: employeeLocal ? employeeLocal : undefined,
                employeeScheduleType: employeeScheduleType ? employeeScheduleType : undefined, 
                employeeScheduleMonday: employeeScheduleMonday && employeeScheduleMonday != '0' && employeeScheduleMonday != 0 ? employeeScheduleMonday : 'n/d', 
                employeeScheduleTuesday: employeeScheduleTuesday && employeeScheduleTuesday != '0' && employeeScheduleTuesday != 0 ? employeeScheduleTuesday : 'n/d', 
                employeeScheduleWednesday: employeeScheduleWednesday && employeeScheduleWednesday != '0' && employeeScheduleWednesday != 0 ? employeeScheduleWednesday : 'n/d', 
                employeeScheduleThursday: employeeScheduleThursday && employeeScheduleThursday != '0' && employeeScheduleThursday != 0 ? employeeScheduleThursday : 'n/d', 
                employeeScheduleFriday: employeeScheduleFriday && employeeScheduleFriday != '0' && employeeScheduleFriday != 0 ? employeeScheduleFriday : 'n/d',
                employeeScheduleSaturday: employeeScheduleSaturday && employeeScheduleSaturday != '0' && employeeScheduleSaturday != 0 ? employeeScheduleSaturday : 'n/d', 
                employeeScheduleSunday: employeeScheduleSunday && employeeScheduleSunday != '0' && employeeScheduleSunday != 0 ? employeeScheduleSunday : 'n/d', 
                employeeCCU: !employeeCCU ? undefined : employeeCCU,
                employeeVT: employeeVT ? employeeVT : undefined, 
                advanceSalary: advanceSalary ? advanceSalary : undefined,
                employeeValueVT: !employeeValueVT ? undefined : employeeValueVT,
                advanceSalaryPorcentage: !advanceSalaryPorcentage ? undefined : advanceSalaryPorcentage,
                employeeExperience: employeeExperience ? employeeExperience : undefined, 
                lifeInsurance: lifeInsurance ? lifeInsurance : undefined, 
                insalubrity: insalubrity ? insalubrity : undefined, 
                periculosity: periculosity ? periculosity : undefined, 
                paymentPeriod: paymentPeriod ? paymentPeriod : undefined, 
                paymentMethod: paymentMethod ? paymentMethod : undefined, 
                employeeSexualGender: employeeSexualGender ? employeeSexualGender : undefined,
                employeeBank: !employeeBank ? undefined : employeeBank,
                employeBankAgencie: !employeBankAgencie ? undefined : employeBankAgencie,
                employeeBankAccountType: !employeeBankAccountType ? undefined : employeeBankAccountType,
                employeeBankAccount: !employeeBankAccount ? undefined : employeeBankAccount,
                employeeBankAccountDigit: !employeeBankAccountDigit ? undefined : employeeBankAccountDigit,
                civilStatus: civilStatus ? civilStatus : undefined, 
                levelOfEducation: levelOfEducation ? levelOfEducation : undefined, 
                birthday: birthday ? birthday : undefined, 
                nationality: nationality ? nationality : undefined, 
                employeeUf: employeeUf ? employeeUf : undefined, 
                employeeCity: employeeCity ? employeeCity : undefined,
                workCard: workCard ? workCard : undefined, 
                workCardSerie: workCardSerie ? workCardSerie : undefined, 
                workCardUf: !workCardUf || workCardUf == -1 ? undefined : workCardUf,
                workCardEmission: !workCardEmission ? undefined : workCardEmission,
                cpf: cpf ? cpf : undefined,
                pis: !pis ? undefined : pis?.replace(/[^\d]+/g, ''),
                emissionPis: !emissionPis ? undefined : emissionPis,
                rg: !rg ? undefined : rg,
                sendingAgencyRG: !sendingAgencyRG ? undefined : sendingAgencyRG,
                EmissionRG: !EmissionRG ? undefined : EmissionRG,
                rgUf: !rgUf ? undefined : rgUf,
                voterRegistration: !voterRegistration ? undefined : voterRegistration,
                voterRegistrationZone: !voterRegistration ? undefined : voterRegistrationZone,
                voterRegistrationSection: !voterRegistration ? undefined : voterRegistrationSection,
                cnh: !cnh ? undefined : cnh,
                cnhIssuer: !cnhIssuer ? undefined : cnhIssuer,
                cnhCategory: !cnhCategory ? undefined : cnhCategory,
                cnhValidity: !cnhValidity ? undefined : cnhValidity,
                cnhUf: !cnhUf ? undefined : cnhUf,
                cnhFirstDate: !cnhFirstDate ? undefined : cnhFirstDate,
                cnhDispatchDate: !cnhDispatchDate ? undefined : cnhDispatchDate,
                militaryServiceCard: !militaryServiceCard ? undefined : militaryServiceCard,
                militaryServiceCardUf: !militaryServiceCardUf ? undefined : militaryServiceCardUf,
                deficiencyType: !deficiencyType ? undefined : deficiencyType,
                deficiencyRehabilitatedInss: !deficiencyRehabilitatedInss ? undefined : deficiencyRehabilitatedInss,
                deficiencyPcdQuote: !deficiencyPcdQuote ? undefined : deficiencyPcdQuote,
                deficiency: deficiency ? deficiency : undefined, 
                employeeRace: employeeRace ? employeeRace : undefined, 
                addressCountry: addressCountry ? addressCountry : undefined, 
                addressCep: addressCep ? addressCep?.replace('-', '') : undefined, 
                addressState: addressState ? addressState : undefined,
                addressCity: addressCity ? addressCity : undefined, 
                addressDistrict: addressDistrict ? addressDistrict : undefined, 
                addressRoad: addressRoad ? addressRoad : undefined, 
                addressNumber: addressNumber ? addressNumber : undefined, 
                addressType: addressType ? addressType : undefined,
                addressComplement: !addressComplement ? undefined : addressComplement,
                phoneDdd: !phoneDdd ? undefined : phoneDdd,
                phoneNumber: !phoneNumber ? undefined : phoneNumber,
                employeeEmail: !employeeEmail ? undefined : employeeEmail,
                employeeEmailCorporate: !employeeEmailCorporate ? undefined : employeeEmailCorporate,
                dateEmploymentExamination: !dateEmploymentExamination ? undefined : dateEmploymentExamination,
                fatherName: !fatherName ? undefined : fatherName,
                firstJob: firstJob,
                employeeWorkplace: !employeeWorkplace ? undefined : employeeWorkplace,
                motherName: motherName ? motherName : undefined, 
                dependents: dependents ? dependents : undefined,
                observation: observation ? observation : undefined,
                traineeInsurancePolicy: traineeInsurancePolicy ? traineeInsurancePolicy : undefined,
                traineeEndForecast: traineeEndForecast ? traineeEndForecast : undefined,
                traineeEducationalInstitution: traineeEducationalInstitution ? traineeEducationalInstitution : undefined,
                traineeCodSupervisor: traineeCodSupervisor ? traineeCodSupervisor : undefined,
                traineeOrigin: traineeOrigin ? traineeOrigin : undefined,
                traineeEducationLevel: traineeEducationLevel ? traineeEducationLevel : undefined,
                foreignResidenceTime: foreignResidenceTime ? foreignResidenceTime : undefined,
                foreignCondition: foreignCondition ? foreignCondition : undefined,
                foreignFinishResidence: foreignFinishResidence ? foreignFinishResidence : undefined,
                foreignArrivalDate: foreignArrivalDate ? foreignArrivalDate : undefined,
                foreignRegistry: foreignRegistry ? foreignRegistry : undefined,
                foreignDateExpRegistry: foreignDateExpRegistry ? foreignDateExpRegistry : undefined,
                foreignVisaValidity: foreignVisaValidity ? foreignVisaValidity : undefined,
                foreignDateValidityCTPS: foreignDateValidityCTPS ? foreignDateValidityCTPS : undefined,
                foreignCasWithBrazilian: foreignCasWithBrazilian ? foreignCasWithBrazilian : undefined,
                foreignBrazilianChildren: foreignBrazilianChildren ? foreignBrazilianChildren : undefined,
                advanceMonthAdmission: advanceMonthAdmission ? advanceMonthAdmission : undefined,
                natureExpenses: natureExpense ? natureExpense : undefined,
                insalubrityPercentage: insalubrityPercentage ? insalubrityPercentage : undefined, 
                periculosityPercentage: periculosityPercentage ? periculosityPercentage : undefined,
                nickname: nickname ? nickname : undefined,
                digitAgency: digitAgency ? digitAgency : undefined,
                apprenticeInstitutionName: apprenticeInstitutionName ?? undefined,
                apprenticeInstitutionCNPJ: apprenticeInstitutionCNPJ ? apprenticeInstitutionCNPJ?.replace('-', '')?.replace('/', '')?.replaceAll('.', '') : undefined,
                apprenticeDateFinish: apprenticeDateFinish ?? undefined,
                gratificationFunction: gratificationFunction ?? undefined
            },{
                headers: {
                    authorization: user?.token
                }
            })
            setInsideLoading(false)
            setShowNotificationModal(true)
            setNotificationModalText('Admissão salva com sucesso')
            return navigate('/employeeConsult')

        } catch (error) {
            setInsideLoading(false)
            console.log(error)
            alert ('Erro ao salvar colaborador')
        }

    }

    function schedulePDFForm(){
        return (
            <div className='modalFields h-fit gap-1'>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleType'] && (employeeScheduleType == '0' || employeeScheduleType == '') ? 'red' : '#464545' }} htmlFor="employeeScheduleType">Tipo de escala</label>
                    <select style={{ border: missingRequiredFieldsModal['employeeScheduleType'] && (employeeScheduleType == '0' || employeeScheduleType == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} name="" id="employeeScheduleType" value={employeeScheduleType} onChange={(e) => { setEmployeeScheduleType(parseInt(e.target.value)) }}>
                        <option value="0">-Selecione uma opção-</option>
                        <option value="1">5x1</option>
                        <option value="2">5x2</option>
                        <option value="3">6x1</option>
                        <option value="4">12x36</option>
                        <option value="5">3x1</option>
                    </select>
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleMonday'] && employeeScheduleMonday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleMonday">Horário de Segunda</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleMonday'] && employeeScheduleMonday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleMonday} type="text" id='employeeScheduleMonday' onChange={(e) => { setEmployeeScheduleMonday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleTuesday'] && employeeScheduleTuesday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleTuesday">Horário de Terça</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleTuesday'] && employeeScheduleTuesday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleTuesday} type="text" id='employeeScheduleTuesday' onChange={(e) => { setEmployeeScheduleTuesday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleWednesday'] && employeeScheduleWednesday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleWednesday">Horário de Quarta</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleWednesday'] && employeeScheduleWednesday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleWednesday} type="text" id='employeeScheduleWednesday' onChange={(e) => { setEmployeeScheduleWednesday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleThursday'] && employeeScheduleThursday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleThursday">Horário de Quinta</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleThursday'] && employeeScheduleThursday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleThursday} type="text" id='employeeScheduleThursday' onChange={(e) => { setEmployeeScheduleThursday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleFriday'] && employeeScheduleFriday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleFriday">Horário de Sexta</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleFriday'] && employeeScheduleFriday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleFriday} type="text" id='employeeScheduleFriday' onChange={(e) => { setEmployeeScheduleFriday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleSaturday'] && employeeScheduleSaturday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleSaturday">Horário de Sabádo</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleSaturday'] && employeeScheduleSaturday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleSaturday} type="text" id='employeeScheduleSaturday' onChange={(e) => { setEmployeeScheduleSaturday(e.target.value) }} />
                </div>
                <div>
                    <label style={{ color: missingRequiredFieldsModal['employeeScheduleSunday'] && employeeScheduleSunday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleSunday">Horário de Domingo</label>
                    <input style={{ border: missingRequiredFieldsModal['employeeScheduleSunday'] && employeeScheduleSunday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleSunday} type="text" id='employeeScheduleSunday' onChange={(e) => { setEmployeeScheduleSunday(e.target.value) }} />
                </div>
            </div>
        )
    }

    function scrollTopDiv(){
        const myDiv = document.getElementById('partForm')
        myDiv.scrollTop = 0
    }

    return (
        <div className='pb-[20rem]'>
            <Modal>
                { show && errorSubmit ?
                    <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                        <div className='flex justify-between pl-3 py-5 items-center'>
                            <p className='flex flex-row font-extrabold  items-center text-lg text-red-700'>
                                <FiAlertTriangle className='text-red-700 mr-7'/> Erro ao tentar realizar solicitação de admissão.<br/> Se o problema persistir entre em contato com o suporte
                            </p>
                            <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                        </div>
                    </div>
                    : show && buttonSave ?
                        <div className={`h-fit  max-h-[35rem] flex flex-col bg-gray-200 rounded-lg w-[28rem]`}>
                            <div className='flex justify-between pl-3 py-5 items-center'>
                                <p className='flex flex-row font-extrabold items-center text-lg'>
                                    <span className='mr-7 text-red-600'>Alerta!</span> 
                                </p>
                                <FiX className='text-gray-500 text-2xl mr-2 cursor-pointer' onClick={e => setShow(false)} title={'Fechar'}/>
                            </div>
                            <p className='text-center mb-5 text-[1rem] p-4'>
                                <b>Você não está enviando a admissão para a folha.</b><br/>
                                Se você deseja enviar para a Folpag clique em <b>"Enviar"</b>.<br/>
                                Caso queira salvar, clique em "Prosseguir".
                            </p>
                            <div className='mb-4 flex flex-row justify-center items-center gap-4'>
                                <Button background={'bg-red-800'} onClick={() => {setShow(false)}}>Cancelar</Button>
                                <Button background={'bg-green-800'} type={'button'} onClick={() => {setShow(false); handleSave()}}>Prosseguir</Button>
                            </div>
                        </div>
                    :
                    errorFields.name === 'date' ?
                        (
                            <div className='alertMsg'>
                                <p>{dateError.message}</p>
                                <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                            </div>
                        ) :
                        errorFields.name === 'invalidCPF' ?
                            (
                                <div className='alertMsg'>
                                    <p>Verifique o campo CPF em vermelho na aba: "Dados do contrato de trabalho"</p>
                                    <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                </div>
                            ) :
                            errorFields.name === 'invalidDependentCPF' ?
                                (
                                    <div className='alertMsg'>
                                        <p>Verifique o campo CPF em vermelho na aba: "Dados de dependentes" os CPFs não podem serem iguais ou inválidos</p>
                                        <button onClick={() => { setShow(false); setErrorFields({ name: '' }) }}>Fechar</button>
                                    </div>
                                ) :
                                errorFields.name === 'contractForm' ?
                                    (
                                        <div className='bg-white w-[95%] rounded-md lg:rounded-none lg:w-[40rem] h-[20rem] flex flex-col items-center justify-center'>
                                            <p className='text-base font-semibold text-center'>Verifique os campos em vermelho na aba: "Dados do contrato de trabalho"</p>
                                            <button
                                                onClick={() => { setShow(false); setErrorFields({ name: '' }) }}
                                                className='mt-7 bg-primaryDefaultLight text-white w-[10rem] h-[2rem] duration-300 lg:hover:bg-white 
                                                    lg:hover:border lg:hover:border-[#005F9E] lg:hover:text-[#005F9E] lg:cursor-pointer'
                                            >
                                                Fechar
                                            </button>
                                        </div>
                                    ) :
                                    errorFields.name === 'employeeForm' ?
                                        (
                                            <div className='bg-white w-[95%] rounded-md lg:rounded-none lg:w-[40rem] h-[20rem] flex flex-col items-center justify-center'>
                                                <p className='text-base font-semibold text-center'>Verifique os campos em vermelho na aba: "Dados do empregado"</p>
                                                <button 
                                                    onClick={() => { setShow(false); setErrorFields({ name: '' }) }}
                                                    className='mt-7 bg-primaryDefaultLight text-white w-[10rem] h-[2rem] duration-300 lg:hover:bg-white 
                                                        lg:hover:border lg:hover:border-[#005F9E] lg:hover:text-[#005F9E] lg:cursor-pointer'
                                                >
                                                    Fechar
                                                </button>
                                            </div>
                                        ) :
                                        errorFields.name === 'dependentsForm' ?
                                            (
                                                <div className='bg-white w-[95%] rounded-md lg:rounded-none lg:w-[40rem] h-[20rem] flex flex-col items-center justify-center'>
                                                    <p className='text-base font-semibold text-center'>Verifique os campos em vermelho na aba: "Dados dos dependentes"</p>
                                                    <button 
                                                        onClick={() => { setShow(false); setErrorFields({ name: '' }) }}
                                                        className='mt-7 bg-primaryDefaultLight text-white w-[10rem] h-[2rem] duration-300 lg:hover:bg-white 
                                                            lg:hover:border lg:hover:border-[#005F9E] lg:hover:text-[#005F9E] lg:cursor-pointer'
                                                    >
                                                        Fechar
                                                    </button>
                                                </div>
                                            ) :
                                            (
                                                <>
                                                    <div className={`${!show ? 'hiddenModal' : 'w-[95%] lg:w-[40rem]'} flex flex-col items-center justify-center`}>
                                                        <div className='modalParamsDescription bg-slate-200 w-full flex items-center py-4 px-2 font-bold'>
                                                            <FiChevronRight /> 
                                                            <p>Informe abaixo a escala do colaborador.</p>
                                                        </div>
                                                        <div className='modalFields h-fit gap-1'>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleType'] && (employeeScheduleType == '0' || employeeScheduleType == '') ? 'red' : '#464545' }} htmlFor="employeeScheduleType">Tipo de escala</label>
                                                                <select style={{ border: missingRequiredFieldsModal['employeeScheduleType'] && (employeeScheduleType == '0' || employeeScheduleType == '') ? '1px solid #e2a3a7' : '1px solid #999999' }} name="" id="employeeScheduleType" value={employeeScheduleType} onChange={(e) => { setEmployeeScheduleType(parseInt(e.target.value)) }}>
                                                                    <option value="0">-Selecione uma opção-</option>
                                                                    <option value="1">5x1</option>
                                                                    <option value="2">5x2</option>
                                                                    <option value="3">6x1</option>
                                                                    <option value="4">12x36</option>
                                                                    <option value="5">3x1</option>
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleMonday'] && employeeScheduleMonday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleMonday">Horário de Segunda</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleMonday'] && employeeScheduleMonday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleMonday} type="text" id='employeeScheduleMonday' onChange={(e) => { setEmployeeScheduleMonday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleTuesday'] && employeeScheduleTuesday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleTuesday">Horário de Terça</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleTuesday'] && employeeScheduleTuesday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleTuesday} type="text" id='employeeScheduleTuesday' onChange={(e) => { setEmployeeScheduleTuesday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleWednesday'] && employeeScheduleWednesday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleWednesday">Horário de Quarta</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleWednesday'] && employeeScheduleWednesday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleWednesday} type="text" id='employeeScheduleWednesday' onChange={(e) => { setEmployeeScheduleWednesday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleThursday'] && employeeScheduleThursday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleThursday">Horário de Quinta</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleThursday'] && employeeScheduleThursday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleThursday} type="text" id='employeeScheduleThursday' onChange={(e) => { setEmployeeScheduleThursday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleFriday'] && employeeScheduleFriday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleFriday">Horário de Sexta</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleFriday'] && employeeScheduleFriday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleFriday} type="text" id='employeeScheduleFriday' onChange={(e) => { setEmployeeScheduleFriday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleSaturday'] && employeeScheduleSaturday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleSaturday">Horário de Sabádo</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleSaturday'] && employeeScheduleSaturday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleSaturday} type="text" id='employeeScheduleSaturday' onChange={(e) => { setEmployeeScheduleSaturday(e.target.value) }} />
                                                            </div>
                                                            <div>
                                                                <label style={{ color: missingRequiredFieldsModal['employeeScheduleSunday'] && employeeScheduleSunday == '' ? 'red' : '#464545' }} htmlFor="employeeScheduleSunday">Horário de Domingo</label>
                                                                <input style={{ border: missingRequiredFieldsModal['employeeScheduleSunday'] && employeeScheduleSunday == '' ? '1px solid #e2a3a7' : '1px solid #999999' }} value={employeeScheduleSunday} type="text" id='employeeScheduleSunday' onChange={(e) => { setEmployeeScheduleSunday(e.target.value) }} />
                                                            </div>
                                                        </div>
                                                        <button className='buttonSchedule' onClick={() => { if (validateFormModal() === 0) { setShow(false) } }}>Confirmar</button>
                                                    </div>
                                                </>
                                            )
                }
            </Modal>
            <div className='animate-[wiggleLeft_1s_ease-in-out] flex flex-row pt-12 justify-center w-full h-fit mb-20'>
                <form className={'containerFormsRequestAdmission w-[90%] lg:pb-3 lg:h-full h-[90vh]'} onSubmit={handleSubmit} >
                    {
                        // Apenas para aparecer em tela, funcionalidade tiradas para o usuário não apertar mais de uma vez enquanto fizer o Envio.
                        insideLoading ?
                        <>
                            <div className='hidden lg:flex headerFormRequestAdmission opacity-50'>
                                <div className={selectedForm === 1 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Dados do Contrato de Trabalho</p></div>
                                <div className={selectedForm === 2 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Dados do Empregado</p></div>
                                <div className={selectedForm === 3 ? 'hasHoverRequest selected' : 'hasHoverRequest'}><p>Familiares e Dados de dependentes</p></div>
                                <div>
                                    <button type='button'> <p> Voltar </p> </button>
                                    <button type='button'> <p> Salvar </p> </button>
                                    <button type='button'> <p> Gerar PDF </p> </button>
                                    <button type='button'> <FiUserPlus /> <p> Enviar </p> </button>
                                </div>
                            </div>
                            <div className='flex lg:hidden gap-2 justify-around h-10 text-center bg-[#ececec] text-primaryDefaultLight relative border-l border-l-[#ececec] border-r border-r-[#ececec] border-b border-b-[#005F9E] opacity-50'>
                                <div className={selectedForm === 1 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'}>
                                    <p className='text-xs'>{selectedForm === 1 ? 'Contrato de Trabalho' : 'Contrato...'}</p>
                                </div>
                                <div className={selectedForm === 2 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'}>
                                    <p className='text-xs'>{selectedForm === 2 ? 'Empregado' : 'Empre...'}</p>
                                </div>
                                <div className={selectedForm === 3 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'}>
                                    <p className='text-xs'>{selectedForm === 3 ? 'Dependentes' : 'Depe...'}</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='hidden lg:flex headerFormRequestAdmission'>
                                <div className={selectedForm === 1 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(1)}><p>Dados do Contrato de Trabalho</p></div>
                                <div className={selectedForm === 2 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(2)}><p>Dados do Empregado</p></div>
                                <div className={selectedForm === 3 ? 'hasHoverRequest selected' : 'hasHoverRequest'} onClick={() => checkFillTabs(3)}><p>Familiares e Dados de dependentes</p></div>
                                <div>
                                    <button onClick={() => navigate(-1)} type='button'> <p> Voltar </p> </button>
                                    <button onClick={() => {setShow(true); setButtonSave(true)}} type='button'> <p> Salvar </p> </button>
                                    <button onClick={generatePdf} type='button'> <p> Gerar PDF </p> </button>
                                    <button type='submit'> <FiUserPlus /> <p> Enviar </p> </button>
                                </div>
                            </div>
                            <div className='flex lg:hidden gap-2 justify-around h-10 text-center bg-[#ececec] text-primaryDefaultLight relative border-l border-l-[#ececec] border-r border-r-[#ececec] border-b border-b-[#005F9E]'>
                                <div className={selectedForm === 1 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => checkFillTabs(1)}>
                                    <p className='text-xs'>{selectedForm === 1 ? 'Contrato de Trabalho' : 'Contrato...'}</p>
                                </div>
                                <div className={selectedForm === 2 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => checkFillTabs(2)}>
                                    <p className='text-xs'>{selectedForm === 2 ? 'Empregado' : 'Empre...'}</p>
                                </div>
                                <div className={selectedForm === 3 ? 'p-2 bg-white w-full text-primaryDefaultLight border-l border-l-white border-r border-r-white border-b border-b-[#005f9e36]' : 'p-2'} onClick={() => checkFillTabs(3)}>
                                    <p className='text-xs'>{selectedForm === 3 ? 'Familiares' : 'Fami...'}</p>
                                </div>
                            </div>
                        </>
                    } 
                    <div id='divMainContainerForm' className='w-full h-full overflow-hidden'>
                        {
                            loading ?
                                (
                                    <div className='loadingFormRequest'>
                                        <Loader />
                                    </div>
                                ) : selectedForm === 1 ? 
                                        contractDataForm()
                                    : selectedForm === 2 ? 
                                        employeeDataForm()
                                    : 
                                        dependentsForm()
                        }
                    </div>
                    <div id="exportEmployee">
                        <h1>Folpag Connect</h1>
                        <h2>Dados de Contrato</h2>
                            {contractDataForm()}
                        <h2>Dados do Empregado</h2>
                            {employeeDataForm() }                            
                        <h2>Familiares e Dados de dependentes</h2>
                            {dependentsForm()}
                        {/* Campos apenas para exibir no PDF*/}
                        <h2 className='hidden'>Dados da escala</h2>
                        {schedulePDFForm()}
                    </div>
                    {
                        insideLoading ?
                        <div className='flex lg:hidden absolute py-1 z-40 bottom-8 bg-white h-10 w-full flex-row items-center justify-around opacity-50'>
                            <button type='button' className='p-1 rounded-sm bg-primaryDefaultLight text-white'>Voltar</button>
                            <button type='button' className='p-1 rounded-sm bg-primaryDefaultLight text-white'>Salvar</button>
                            <div className='flex flex-row p-1 rounded-sm bg-primaryDefaultLight text-white items-center gap-2'>
                                <p><FiUserPlus /></p>
                                <button type='button'>Enviar</button>
                            </div>
                        </div>
                        :
                        <div className='flex lg:hidden absolute py-1 z-40 bottom-8 bg-white h-10 w-full flex-row items-center justify-around'>
                            <button 
                                onClick={() => navigate(-1)} 
                                type='button'
                                className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                            > 
                                Voltar
                            </button>
                            <button 
                                onClick={() => {setShow(true); setButtonSave(true)}} 
                                type='button'
                                className='p-1 rounded-sm bg-primaryDefaultLight text-white'
                            >
                                Salvar
                            </button>
                            <div className='flex flex-row p-1 rounded-sm bg-primaryDefaultLight text-white items-center gap-2'>
                                <p><FiUserPlus /></p>
                                <button type='submit'>Enviar</button>
                            </div>
                        </div>
                    }
                </form>
            </div>
        </div>
    )

}