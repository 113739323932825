import React, { useContext, createContext, useState, useEffect } from 'react';
import { useLoading } from './useLoad'
import api from '../services/api'

const AuthContext = createContext({ });

export const AuthProvider = ({ children }) => {

    const [ logged, setLogged ] = useState(null);
    const [ user, setUser ] = useState(null);
    const { setLoading } = useLoading()
    const [loadingAuth, setLoadingAuth] = useState(false)

    async function validAuth(){

        setLoading(true)
       
        const token = localStorage.getItem('@auth:token')

        if(token){
            setLoadingAuth(true)
            try{
                const authorized = await api.get(`auth/logged`, {
                    headers: {
                        authorization: token
                    }
                })
                
                if (authorized.data.data?.photo){
                    try {
                        const getPhoto = await api.get(`/api/v1/users/photo`,  {
                            responseType: 'blob',
                            responseEncoding: 'base64',
                            headers: {
                                Authorization: `${token}`
                            }
                        })
                        const fileObj = URL.createObjectURL(getPhoto?.data)
                        setUser({...authorized.data.data, picture: fileObj})
                        setLogged(authorized.data.auth)
                        setLoading(false)
                        setLoadingAuth(false)
                    } catch (error) {
                        console.log('> Foto de perfil não encontrada.')
                    }
                    
                }
                setLoading(false)
                setUser(authorized.data.data)
                setLogged(authorized.data.auth)
                setLoadingAuth(false)

            }catch(error){
                setLoading(false)
                localStorage.removeItem('@auth:token')
                localStorage.removeItem('@user:name')
                localStorage.removeItem('@session:data')
                setLogged(false)
                window.location = '/'
            }
        }else{
            setLoading(false)
            setLogged(false)
        }
        
        setLoading(false)
    }

    useEffect(() => {
        validAuth();
    }, [])

    async function signIn(username, password){
        try{
            localStorage.removeItem('@auth:token')
            localStorage.removeItem('@user:name')
            localStorage.removeItem('@session:data')

            const response = await api.post('/auth/login', { 
                username: username, 
                password: password
            });

            localStorage.setItem('@auth:token', response.data.token);
            localStorage.setItem('@user:name', response.data.username);

            if (response?.data?.data?.photo){
                try {
                    const getPhoto = await api.get(`/api/v1/users/photo`, {
                        responseType: 'blob',
                        responseEncoding: 'base64',
                        headers: {
                            Authorization: `${response?.data?.token}`
                        }
                    })
                    const fileObj = URL.createObjectURL(getPhoto?.data)
                    setUser({...response?.data?.data, picture: fileObj})
                    setLogged(true)
                    setLoading(false)
                    return
                } catch (error) {
                    console.log('> Foto de perfil não encontrada.')
                }
            }

            setUser(response?.data?.data)
            setLogged(true)
            window.location = '/'
            
        }catch (err){
            if(err.message === "Network Error") return alert('Erro interno, se o problema persistir entre em contato com o administrador.')
            return alert('Falha ao realizar Login. Usuário ou Senha inválido!');
        }   
    }

    async function signOut(){
        try {

            await api.put('/auth/logout', {
            }, {
                headers: {
                    Authorization: user?.token
                }
            })

            localStorage.removeItem('@auth:token')
            localStorage.removeItem('@user:name')
            localStorage.removeItem('@session:data')
            
            if(window.location.pathname?.toString().length === 1){                
                window.location = '/'
            }
            return setLogged(false)
        } catch(error){
            console.log(error)
        }
    }

    return(
        <AuthContext.Provider value={{ signIn, logged, setLogged,  user, setUser, signOut, validAuth, loadingAuth, setLoadingAuth }}>
            { children }
        </AuthContext.Provider>
    );
}

export function useAuth(){
    return useContext(AuthContext);
}