/**
 * Desenvolvedor: Tiago Murilo Ochôa da Luz
 * Descrição: Conexão com o backend.
 */
import axios from 'axios';

export const api = axios.create({
    baseURL: ['development', 'dev', 'DEV', 'test'].includes(process.env?.NODE_ENV)
        ? 'http://localhost:8081'
        : 'https://folpagconnect.we4u.net.br'
})

export default api;