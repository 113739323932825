import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts';

export function RadialBarWithTotalAndCurrent({total = 0, current = 0, id }) {

    useEffect(() => {

        const percentage = (current / total) * 100;

        const options = {
            series: [percentage],
            chart: {
                height: 200,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '70%',
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: true,
                            formatter: function () {
                                return current + '/' + total
                            },
                            fontSize: '22px',
                            color: '#311962',
                            offsetY: 5
                        },
                    },
                },
            },
            labels: ['Progresso'],
            colors: current >= total ? ['#2563eb'] : ['#dc2626']
        }

        var chart = new ApexCharts(document.querySelector(`#${id}`), options)
        chart.render()

    }, [])

    return (
        <div id={id}>

        </div>
    )

}